import { FormGroup, NameContainer
 } from "./style";
 import {
    Field, ErrorMessage,
  } from 'formik';

const NameInputs = () => ( 
    <NameContainer>
        <FormGroup>
            <label htmlFor="firstName">First Name</label>
            <Field
            name="firstName"
            type="string"
            className="form-control"
            placeholder="Enter Your First Name" 
            />
            <ErrorMessage
            name="firstName"
            component="div"
            className="alert alert-danger"
            />
        </FormGroup>
        <FormGroup>
            <label htmlFor="lastName">Last Name</label>
            <Field
            name="lastName"
            type="string"
            className="form-control"
            placeholder="Enter Your Last Name"
            />
            <ErrorMessage
            name="lastName"
            component="div"
            className="alert alert-danger"
            />
        </FormGroup>
    </NameContainer>
 );

 
export default NameInputs;