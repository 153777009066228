import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { userInfo } from '../../../redux/authSlice';
import Loading from '../../Loading/Loading';

export const AuthGuard = ({ children }) => {
  const nonAuthPaths = [
    '/accept-invite',
    '/password-reset',
    '/request-reset',
    '/',
    '/login',
    '/location',
    '/purchase-parking-session'
  ];
  const loading = useSelector((state) => state.auth.loading);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const token = JSON.parse(localStorage.getItem('user'))?.accessToken;
  const { state } = useLocation();

  useEffect(() => {
    token && dispatch(userInfo());
    if (pathname === '/') {
      if (token) {
        window.location.replace('/admin');
      }
    } else if (!token && pathname.includes('/admin')) {
      navigate('/');
    } else {
      navigate(pathname);
    }
  }, [token, dispatch, navigate, pathname]);

  const loginUrl = '/auth/login';

  if (auth.error) {
    localStorage.clear();
    if (pathname !== loginUrl) navigate(loginUrl);
    window.location.reload(false);
  }

  if (token) {
    // allow user to hit root or login page
    let dashboardUrl = '/admin';

    if (state?.from) {
      navigate(state?.from);
    }

    // redirect users with token to dashboard if not already on dashboard
    if (pathname === loginUrl) {
      navigate(dashboardUrl);
    }
  } else {
    // unauthenticated user actions
    // allow user to non auth pages
    const isNonAuthPath = nonAuthPaths.some((path) => pathname.includes(path));

    if (isNonAuthPath) return children;
  }

  // if (!loading) dispatch(buildPermsList());

  // if loading show loader else show app
  return loading ? <Loading /> : children;
};
