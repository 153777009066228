import React, { useMemo } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../../redux/authSlice';
import { updateSelectedParams } from '../../../../redux/hotelSlice';
import { conciergeOperatorListing } from "../../../../redux/hotelSlice";
import Select from "react-select";
import { SelectWrapper, SidebarWrapper } from './style';

const Sidebar = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [selectedNavItem, setSelectedNavItem] = useState(Object.values(params)?.[0] !== "" ? Object.values(params)?.[0] : 'dashboard');
  const [darkMode, setDarkMode] = useState(true);
  const userRole = useSelector((state) => state.auth.role) || localStorage.getItem('role');

  useEffect(() => {
    dispatch(conciergeOperatorListing());
  }, [dispatch]);

  const hotelData = useSelector(state => state.hotel);
  const userData = useSelector(state => state.auth.data);
  const operators = hotelData?.userOperators?.user?.operators;
  const { locationOptions, selectedGroup, selectedLocation, locationLabel, groupLabel } = hotelData;
  console.log("hotelData", hotelData);

  let operatorOptions = useMemo(() => { return []}, []);
  if (typeof operators !== 'undefined' && typeof operators[0] !== 'undefined') {
    operatorOptions = operators?.filter(opr => opr?.locations?.length !== 0)?.map((operator) => {
      return {
        label: operator.name,
        value: operator.id,
        locations: operator?.locations?.filter(location => location?.active)
        ?.map((location) => {
          return { label: location?.name, value: location.id, rates: location?.hotel_rates }
        }),
      };
    });
  } else if (typeof operators !== 'undefined') {
    operatorOptions = [{
      label: operators?.name,
      value: operators?.id,
      locations: operators?.locations?.filter(location => location?.active)
      ?.map((location) => {
        return { label: location?.name, value: location.id, rates: location?.hotel_rates }
      })
    }]
  }

  useEffect(() => {
    if (selectedGroup === 0 && typeof operatorOptions !== 'undefined' && operatorOptions?.length !== 0) {
      dispatch(updateSelectedParams({ param: 'selectedGroup', paramVal: operatorOptions[0].value, locationOptions: operatorOptions[0]?.locations, groupLabel: operatorOptions[0]?.label, locationLabel: locationLabel }));
    }
  }, [dispatch, operatorOptions, selectedGroup, locationLabel, groupLabel]);

  useEffect(() => {
    if (selectedLocation === 0 && typeof locationOptions !== 'undefined' && locationOptions?.length !== 0) {
      dispatch(updateSelectedParams({ param: 'selectedLocation', paramVal: locationOptions[0].value, locationLabel: locationOptions[0].label, hotelRates: locationOptions[0]?.rates, groupLabel: groupLabel }));
    }
  }, [dispatch, locationOptions, selectedLocation, locationLabel, groupLabel]);

  const handleLogoutClick = (e) => {
    e.preventDefault();
    localStorage.removeItem('user');
    localStorage.removeItem('role');
    dispatch(logout());
    navigate('/');
  };
  const handleDarkMode = (e) => {
    if (darkMode) {
      document.body.setAttribute('data-sibebarbg', 'color_12');
      document.body.setAttribute('data-nav-headerbg', 'color_12');
      document.body.setAttribute('data-headerbg', 'color_12');
    } else {
      document.body.setAttribute('data-sibebarbg', 'color_1');
      document.body.setAttribute('data-nav-headerbg', 'color_1');
      document.body.setAttribute('data-headerbg', 'color_1');
    }
    setDarkMode(!darkMode);
  }

  return (
    <SidebarWrapper>
      <div className="deznav-scroll">
        <div className=" dropdown header-bx">
          <div className="header-content">
            {Object.entries(userData).length !== 0 &&
              <>
                <h2 className="font-w500" style={{ 'textTransform': 'capitalize' }}>{`${userData?.first_name} ${userData?.last_name}`}</h2>
              </>}
          </div>
        </div>
        <ul className="metismenu" id="menu">
          <li className={selectedNavItem === 'dashboard' ? 'mm-active' : ''}>
            <Link to="/admin" aria-expanded="false" onClick={() => setSelectedNavItem('dashboard')}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path className="fa-primary" d="M0 80C0 53.49 21.49 32 48 32H176C202.5 32 224 53.49 224 80V192C224 218.5 202.5 240 176 240H48C21.49 240 0 218.5 0 192V80zM256 80C256 53.49 277.5 32 304 32H464C490.5 32 512 53.49 512 80V112C512 138.5 490.5 160 464 160H304C277.5 160 256 138.5 256 112V80zM464 352C490.5 352 512 373.5 512 400V432C512 458.5 490.5 480 464 480H304C277.5 480 256 458.5 256 432V400C256 373.5 277.5 352 304 352H464z" /><path style={{ opacity: '.4' }} d="M464 192C490.5 192 512 213.5 512 240V272C512 298.5 490.5 320 464 320H304C277.5 320 256 298.5 256 272V240C256 213.5 277.5 192 304 192H464zM176 272C202.5 272 224 293.5 224 320V432C224 458.5 202.5 480 176 480H48C21.49 480 0 458.5 0 432V320C0 293.5 21.49 272 48 272H176z" /></svg>
              <span className="nav-text">Dashboard</span>
            </Link>
          </li>
          <li className={selectedNavItem === 'add-parking-session' ? 'mm-active' : ''}>
            <Link to="/admin/add-parking-session" aria-expanded="false" onClick={() => setSelectedNavItem('add-parking-session')}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path className="fa-primary" d="M319.9 320c57.41 0 103.1-46.56 103.1-104c0-57.44-46.54-104-103.1-104c-57.41 0-103.1 46.56-103.1 104C215.9 273.4 262.5 320 319.9 320zM369.9 352H270.1C191.6 352 128 411.7 128 485.3C128 500.1 140.7 512 156.4 512h327.2C499.3 512 512 500.1 512 485.3C512 411.7 448.4 352 369.9 352z" /><path style={{ opacity: ".4" }} d="M128 160c44.18 0 80-35.82 80-80S172.2 0 128 0C83.82 0 48 35.82 48 80S83.82 160 128 160zM512 160c44.18 0 80-35.82 80-80S556.2 0 512 0c-44.18 0-80 35.82-80 80S467.8 160 512 160zM551.9 192h-61.84c-12.8 0-24.88 3.037-35.86 8.24C454.8 205.5 455.8 210.6 455.8 216c0 33.71-12.78 64.21-33.16 88h199.7C632.1 304 640 295.6 640 285.3C640 233.8 600.6 192 551.9 192zM185.5 200.1C174.6 194.1 162.6 192 149.9 192H88.08C39.44 192 0 233.8 0 285.3C0 295.6 7.887 304 17.62 304h199.5c-20.38-23.79-33.16-54.29-33.16-88C183.9 210.6 184.9 205.4 185.5 200.1z" /></svg>
              <span className="nav-text">Add Parking Session</span>
            </Link>
          </li>
          <li className={selectedNavItem === 'reports' ? 'mm-active' : ''}>
            <Link to="/admin/reports" aria-expanded="false" onClick={() => setSelectedNavItem('reports')}>
              <svg width="22" height="18" viewBox="0 0 22 18" xmlns="http://www.w3.org/2000/svg"><path d="M21.3125 14.5H2.75V1.4375C2.75 1.05766 2.44234 0.75 2.0625 0.75H0.6875C0.307656 0.75 0 1.05766 0 1.4375V15.875C0 16.6343 0.615742 17.25 1.375 17.25H21.3125C21.6923 17.25 22 16.9423 22 16.5625V15.1875C22 14.8077 21.6923 14.5 21.3125 14.5ZM19.9375 2.125H14.8646C13.9459 2.125 13.4857 3.23574 14.1354 3.88543L15.5276 5.27762L12.375 8.43066L9.22238 5.27805C8.68527 4.74094 7.81473 4.74094 7.27805 5.27805L4.32652 8.22957C4.05797 8.49813 4.05797 8.9334 4.32652 9.20195L5.29848 10.1739C5.56703 10.4425 6.00231 10.4425 6.27086 10.1739L8.25 8.19434L11.4026 11.347C11.9397 11.8841 12.8103 11.8841 13.347 11.347L17.472 7.22195L18.8641 8.61414C19.5138 9.26383 20.6246 8.80363 20.6246 7.88496V2.8125C20.625 2.43266 20.3173 2.125 19.9375 2.125Z" /></svg>
              <span className="nav-text">Report</span>
            </Link>
          </li>
          {userRole === 'hotel administrator' && (
            <>
              <li className={selectedNavItem === 'add-user' ? 'mm-active' : ''}>
                <Link aria-expanded="false" className="has-arrow " to="#" onClick={() => setSelectedNavItem('add-user')}>
                  <svg width="22" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM504 312V248H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V136c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H552v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" /></svg>
                  <span className="nav-text">Add user</span>
                </Link>
              </li>
              <ul className={selectedNavItem !== "add-user" ? "d-none" : ''}>
                <li>
                  <Link aria-expanded="false" className="has-arrow " to="/admin/add-user" onClick={() => setSelectedNavItem('add-user')}>
                    <span className="nav-text">Add user</span>
                  </Link>
                </li>
                <li>
                  <Link aria-expanded="false" className="has-arrow " to="/admin/users" onClick={() => setSelectedNavItem('add-user')}>
                    <span className="nav-text">User Listings</span>
                  </Link>
                </li>
              </ul>
            </>
          )}
        </ul>
        <div style={{ marginTop: '20vh' }}>
          <SelectWrapper>
            {operatorOptions?.length > 1 &&
              <>
                <label htmlFor="operator">Group: </label>
                <Select
                  value={operatorOptions?.filter((operator) => operator.value === selectedGroup)?.[0]}
                  options={operatorOptions}
                  name='operator'
                  onChange={(operator) => dispatch(updateSelectedParams({ param: 'selectedGroup', paramVal: operator?.value, locationOptions: operator?.locations, groupLabel: operator?.label, locationLabel: locationLabel }))}
                  placeholder='Select operator'
                  className='select-dropdown'
                />
              </>
            }
            <label htmlFor="location" style={{ fontSize: "1.15rem" }}>Location: </label>
              <Select
                value={locationOptions?.filter((location) => location.value === selectedLocation)?.[0]}
                options={locationOptions}
                name='location'
                onChange={(location) => dispatch(updateSelectedParams({ param: 'selectedLocation', paramVal: location?.value, locationLabel: location?.label, hotelRates: location?.rates, groupLabel: groupLabel }))}
                placeholder='Select location'
                className='select-dropdown'
              />
          </SelectWrapper>
          <ul className="metismenu" id="menu">
            <li>
              <Link to="#" aria-expanded="false" onClick={handleLogoutClick}>
                <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path className='fa-warning' d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V256c0 17.7 14.3 32 32 32s32-14.3 32-32V32zM143.5 120.6c13.6-11.3 15.4-31.5 4.1-45.1s-31.5-15.4-45.1-4.1C49.7 115.4 16 181.8 16 256c0 132.5 107.5 240 240 240s240-107.5 240-240c0-74.2-33.8-140.6-86.6-184.6c-13.6-11.3-33.8-9.4-45.1 4.1s-9.4 33.8 4.1 45.1c38.9 32.3 63.5 81 63.5 135.4c0 97.2-78.8 176-176 176s-176-78.8-176-176c0-54.4 24.7-103.1 63.5-135.4z" /></svg>
                <span className="nav-text">Logout</span>
              </Link>
            </li>
            {darkMode
              ?
              <li>
                <Link to="#" aria-expanded="false" onClick={handleDarkMode}>
                  <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 0c8.8 0 16 7.2 16 16V96c0 8.8-7.2 16-16 16s-16-7.2-16-16V16c0-8.8 7.2-16 16-16zM0 256c0-8.8 7.2-16 16-16H96c8.8 0 16 7.2 16 16s-7.2 16-16 16H16c-8.8 0-16-7.2-16-16zm400 0c0-8.8 7.2-16 16-16h80c8.8 0 16 7.2 16 16s-7.2 16-16 16H416c-8.8 0-16-7.2-16-16zM256 400c8.8 0 16 7.2 16 16v80c0 8.8-7.2 16-16 16s-16-7.2-16-16V416c0-8.8 7.2-16 16-16zM75 75c6.2-6.2 16.4-6.2 22.6 0l56.6 56.6c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0L75 97.6c-6.2-6.2-6.2-16.4 0-22.6zm0 362c-6.2-6.2-6.2-16.4 0-22.6l56.6-56.6c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6L97.6 437c-6.2 6.2-16.4 6.2-22.6 0zM357.8 154.2c-6.2-6.2-6.2-16.4 0-22.6L414.4 75c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6l-56.6 56.6c-6.2 6.2-16.4 6.2-22.6 0zm0 203.6c6.2-6.2 16.4-6.2 22.6 0L437 414.4c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0l-56.6-56.6c-6.2-6.2-6.2-16.4 0-22.6zM336 256a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zm-192 0a112 112 0 1 1 224 0 112 112 0 1 1 -224 0z" /></svg>
                  <span className="nav-text">Light</span>
                </Link>
              </li>
              :
              <li>
                <Link to="#" aria-expanded="false" onClick={handleDarkMode}>
                  <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M295.8 137.8c1 3.6 4.4 6.2 8.2 6.2s7.1-2.5 8.2-6.2l11-38.6 38.6-11c3.6-1 6.2-4.4 6.2-8.2s-2.5-7.1-6.2-8.2l-38.6-11-11-38.6c-1-3.6-4.4-6.2-8.2-6.2s-7.1 2.5-8.2 6.2l-11 38.6-38.6 11c-3.6 1-6.2 4.4-6.2 8.2s2.5 7.1 6.2 8.2l38.6 11 11 38.6zM403.8 310.8c1.6 5.5 6.6 9.2 12.2 9.2s10.7-3.8 12.2-9.2l16.6-58 58-16.6c5.5-1.6 9.2-6.6 9.2-12.2s-3.8-10.7-9.2-12.2l-58-16.6-16.6-58c-1.6-5.5-6.6-9.2-12.2-9.2s-10.7 3.8-12.2 9.2l-16.6 58-58 16.6c-5.5 1.6-9.2 6.6-9.2 12.2s3.8 10.7 9.2 12.2l58 16.6 16.6 58zM48 320c0-70 50-128.3 116.2-141.3C141.6 206.3 128 241.5 128 280c0 83.2 63.5 151.6 144.7 159.3c-23 15.6-50.8 24.7-80.7 24.7c-79.5 0-144-64.5-144-144zM192 128C86 128 0 214 0 320S86 512 192 512c69.4 0 130.2-36.9 163.9-92c5.3-8.7 4.6-19.9-2-27.8s-17.3-10.8-26.9-7.2c-12.1 4.5-25.3 7-39.1 7c-61.9 0-112-50.1-112-112c0-45 26.6-83.9 65-101.7c9.3-4.3 14.8-14 13.8-24.2s-8.4-18.6-18.3-20.9c-14.3-3.4-29.2-5.2-44.4-5.2z" /></svg>
                  <span className="nav-text">Dark</span>
                </Link>
              </li>
            }
          </ul>
        </div>
        <div className="copyright">
          <h6>Interstate Parking Hotel Portal <span className="fs-14 font-w400 text-nowrap">© {new Date().getFullYear()} All Rights Reserved</span></h6>
        </div>
      </div>
    </SidebarWrapper>
  );
};

export default Sidebar;
