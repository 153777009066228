import React from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import "./index.css";
import { StyledTable } from "./style";
const ReactDataTable = ({ columns, data, filterable, className }) => {

  const defaultFilterMethod = (filter, row) => {
    const searchOpt = typeof row[filter.id] !== 'number' ? row[filter.id]?.toLowerCase() : row[filter.id]?.toString()?.toLowerCase();
    return typeof searchOpt !== "undefined"
      ? String(searchOpt)?.includes(
        filter?.value?.toLowerCase()
      )
      : false;
  };

  return (
    <StyledTable>
      <ReactTable
        key={data?.length}
        data={data}
        columns={columns}
        defaultPageSize={10}
        noDataText="No rows found"
        loadingText="Loading..."
        className={className}
        pageText="Page"
        rowsText="rows"
        filterable={filterable}
        defaultFilterMethod={defaultFilterMethod}
      />
    </StyledTable>
  );
};

export default ReactDataTable;
