import React from 'react';
import { FormGroup, SelectFieldContainer, } from './style';
import { Field, ErrorMessage, } from 'formik';

const SelectFieldInput = () => {

    return (
        <>
            <SelectFieldContainer>
                <FormGroup>
                    <label htmlFor="email">Email Address</label>
                    <Field
                        name="email"
                        type="email"
                        className="form-control"
                        placeholder="Enter Your Email Address"
                    />
                    <ErrorMessage
                        name="email"
                        component="div"
                        className="alert alert-danger"
                    />
                </FormGroup>
                <FormGroup>
                    <label htmlFor="contactno">Contact No.</label>
                    <Field
                        name="contactno"
                        type="number"
                        className="form-control"
                        placeholder="Enter Your Contact No"
                    />
                    <ErrorMessage
                        name="contactno"
                        component="div"
                        className="alert alert-danger"
                    />
                </FormGroup>
            </SelectFieldContainer>
        </>
    )
}

export default SelectFieldInput;
