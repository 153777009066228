import styled from "styled-components";

const OperatorButtonWrapper = styled.div`
  width: 330px;
    height: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
    width: 152px;
    height: 45px;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 16px;
    gap: 10px;
    isolation: isolate;
    background-color: #ffd100;
    border: 1px solid #ffd100;
    border-radius: 4px;
    margin-right:2px;
  }

  .operator-cancel {
    background-color: #b6b6b6;
    border: 1px solid #b6b6b6;
  }

  @media (max-width:420px) {
    flex-wrap: wrap;
    width: 100%;
    height: 110px;
    margin-top: 20px;
    justify-content: center;
    gap:10px;
  }
`;

export {
  OperatorButtonWrapper,
}

