import React, { useState } from 'react';
import Modal from '../../../components/Modal';
import TermsAndCondition from '../../../pages/Terms&Conditions/TermsAndCondition';

const Footer = () => {
  const [showTnCModal, setShowTnCModal] = useState(false);
  return (
    <div className="footer">
      <div className="copyright">
        <p>
          Copyright &copy; {new Date().getFullYear()} Interstate Parking . All rights reserved.{' '}
          <a href="#/" onClick={() => setShowTnCModal(!showTnCModal)}>Privacy Policy | Terms of Use</a>
        </p>
      </div>
      <Modal show={showTnCModal} close={setShowTnCModal}>
        <TermsAndCondition close={setShowTnCModal} />
      </Modal>
    </div>
  );
};

export default Footer;
