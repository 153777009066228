import styled from 'styled-components';

const StyledButton = styled.button`
  height: 40px;
  background-color: #ffd100;
  border: none;
  width: 170px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.008em;
  border-radius: 4px;
  a {
    padding: 0 20px;
    color: #fff;
    font-weight: 600;
    font-size: 0.875rem;
    text-decoration: none;
  }

  @media (max-width : 767px){
    min-width : 110px;
    width:100%;

    a{
      padding:0 5px;
      font-size : 14px;
    }

    @media (max-width : 480px){
      min-width : 80px;
      height:35px;
      a{
        padding:0 5px;
        font-size : 12px;
      }
    }
  }
`;

export {
  StyledButton,
};
