import { OperatorButtonWrapper } from "./style";

const SaveAndCancelButton = (props) => (
  <OperatorButtonWrapper>
    {(typeof props !== 'undefined' && typeof props.handleCancelButton !== 'undefined') ?
      <button type="button" className="operator-cancel" onClick={() => { props.handleCancelButton() }}>
        Cancel
      </button> :
      <button type="button" className="operator-cancel">
        Cancel
      </button>
    }
    <button type="submit" className="">
      Save
    </button>
  </OperatorButtonWrapper>
);

export default SaveAndCancelButton;