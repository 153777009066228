import styled from 'styled-components';

const NameContainer = styled.div`
  display: flex;
  gap: 1rem;

  input::placeholder {
    color:#ced4da;
  }
`;

const FormGroup = styled.div`
  > label {
    color: #555;
    font-weight: 500;
    font-style: normal;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }
`;

export {
  NameContainer,
  FormGroup,    
}