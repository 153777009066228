import Main from "./Main";
import Footer from "./Footer";
import NavHeader from "./NavHeader";
import Sidebar from "./Sidebar";
import { useSelector } from 'react-redux';

const AdminLayout = (props) => {
  const showLeftBarStatus = useSelector((state) => state.dashboard.showLeftBar);
  return (
    <div id="main-wrapper" className={showLeftBarStatus ? "menu-toggle show" : "show"}>
      <NavHeader {...props} />
      <Sidebar {...props} />
      <Main {...props} />
      <Footer {...props} />
    </div>
  )
};

export default AdminLayout;
