import { useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';


const ConfirmAlert = ({ confirmTitle, confirmMessage, labelYes, labelNo, buttonYesEvent, buttonNoEvent, closeOnClickOutside, closeOnEscape }) => {
    confirmTitle = (typeof confirmTitle !== 'undefined') ? confirmTitle : 'Confirm!';
    confirmMessage = (typeof confirmMessage !== 'undefined') ? confirmMessage : 'Are you sure to do this action?';
    labelYes = (typeof labelYes !== 'undefined') ? labelYes : 'Yes';
    labelNo = (typeof labelNo !== 'undefined') ? labelNo : 'No';
    buttonYesEvent = (typeof buttonYesEvent !== 'undefined') ? buttonYesEvent : null;
    buttonNoEvent = (typeof buttonNoEvent !== 'undefined') ? buttonNoEvent : null;
    closeOnClickOutside = (typeof closeOnClickOutside !== 'undefined') ? closeOnClickOutside : true;
    closeOnEscape = (typeof closeOnEscape !== 'undefined') ? closeOnEscape : true;
    useEffect(() => {
        confirmAlert({
            title: confirmTitle,
            message: confirmMessage,
            buttons: [
                {
                    label: labelYes,
                    onClick: () => buttonYesEvent !== null ? buttonYesEvent() : ''
                },
                {
                    label: labelNo,
                    onClick: () => buttonNoEvent !== null ? buttonNoEvent() : ''
                }
            ],
            closeOnClickOutside: closeOnClickOutside,
            closeOnEscape: closeOnEscape
        });
    }, [confirmTitle, confirmMessage, labelYes, labelNo, buttonYesEvent, buttonNoEvent, closeOnClickOutside, closeOnEscape]);

    return null;
}

export default ConfirmAlert;