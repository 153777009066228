import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif; 
    font-style: normal;
  }
  body {
    background: #efefef;
  }
`;

export const AppContainer = styled.div`
  margin: auto;
  
  .menu-toggle.show {
    .dropdown.header-bx, .select-wrapper, .settings-text {
      display: none;
    }
  }
  @media (max-width:1023px){
    .nav-header{width: 17rem;}
  }
  @media (max-width:767px){
    .nav-header{width: 5rem;}
  }
`;

export const InterStateWrapper = styled.div.attrs({ 'className': 'interstate-wrapper' })`
  background: linear-gradient(180deg, #FFFFFF 0.01%, #CBCBCB 0.02%, #8F8F8F 0.03%, #000000 100%);
  min-height: 100vh;
  @media (max-width:991px){
      .container{
        max-width:100%;
        padding:0px 30px;
      }
    }
`;