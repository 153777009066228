/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  StyledWrapper,
  FormWrapper,
  FormikSectionContainer,
  FormikSection,
  AddUserHeading,
  AddUserContainer,
} from "./style";
import NameInputs from "../../components/NameInputs/NameInputs";
import SelectFieldInput from "./SelectFieldInput";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import SaveAndCancelButton from "../../components/SaveAndCancelButton/SaveAndCancelButton";
import { createUser, getUser } from "../../redux/usersSlice";

const NewUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.users.user);
  const { userId } = useParams();
  useEffect(() => {
    if (typeof userId !== "undefined") {
      dispatch(getUser(userId));
    }
  }, [dispatch, userId]);
  let defaultInitialValues = {
    firstName: "",
    lastName: "",
    email: "",
    contactno: "",
    platform: "hotel",
  };
  if (typeof userId !== "undefined" && typeof user !== "undefined" && user !== null && typeof user.user !== "undefined") {
    defaultInitialValues = {
      firstName: user.user.first_name !== null ? user.user.first_name : "",
      lastName: user.user.last_name !== null ? user.user.last_name : "",
      email: user.user.email !== null ? user.user.email : "",
      contactno: user.user.contact_no !== null ? user.user.contact_no : "",
    };
  }
  let [initialValues, setInitialValues] = useState(defaultInitialValues);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("This field is required."),
    lastName: Yup.string().required("This field is required."),
    contactno: Yup.number().required("This field is required."),
    email: Yup.string().email("This is not a valid email.").required("This field is required."),
  });

  const handleSubmitHandler = (formValue) => {
    const { email, firstName, lastName, contactno } = formValue;
    dispatch(
      createUser({
        email,
        firstName,
        lastName,
        contactno,
        platform: 'hotel',
        userId
      })
    ).unwrap()
      .then((data) => {
        if (data) {
          navigate(`/admin/users`);
        } else {
          toast.error(data?.message);
          setInitialValues({
            firstName,
            lastName,
            email,
            contactno,
          });
        }
      })
      .catch((err) => {
        toast.error(err.message,);
      });
  };

  const handleCancelButton = () => {
    navigate(`/admin/users`);
  };

  return (
    <StyledWrapper>
      <FormikSectionContainer>
        <AddUserHeading className="ps-5 pt-5">
          {typeof userId !== "undefined" &&
            typeof user !== "undefined" &&
            user !== null
            ? (
              <span>Update user</span>
            ) : (
              <span>Add user</span>
            )}
        </AddUserHeading>
        <AddUserContainer className="px-5 pt-3">
          <FormikSection>
            <Formik
              enableReinitialize
              initialValues={
                typeof userId !== "undefined"
                  ? defaultInitialValues
                  : initialValues
              }
              validationSchema={validationSchema}
              onSubmit={handleSubmitHandler}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  {document
                    .querySelector(".alert")
                    ?.scrollIntoView({ behavior: "smooth", block: "center" })}
                  <FormWrapper>
                    <NameInputs />
                    <SelectFieldInput
                      setFieldValue={setFieldValue}
                      values={values}
                    />
                    <SaveAndCancelButton
                      handleCancelButton={handleCancelButton}
                    />
                  </FormWrapper>
                </Form>
              )}
            </Formik>
          </FormikSection>
        </AddUserContainer>
      </FormikSectionContainer>
    </StyledWrapper>
  );
};
export default NewUser;
