import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AdminDashboard from '../../../pages/AdminDashboard';
import NewParkingSession from '../../../pages/NewParkingSession';
import Reports from '../../../pages/Reports';
import NewUser from '../../../pages/NewUser';
import Users from '../../../pages/Users/Users';

const Main = (props) => {
  return (
    <div className="content-body">
      <div className="container-fluid">
        <Routes>
          <Route path="/" element={<AdminDashboard />} />
          <Route path="/add-parking-session" element={<NewParkingSession />} />
          <Route path="/session-details/:parkingId/edit" element={<NewParkingSession />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/add-user" element={<NewUser />} />
          <Route path="/add-user/:userId/edit" element={<NewUser />} />
          <Route path="/users" element={<Users />} />
        </Routes>
      </div>
    </div>
  )
};

export default Main;
