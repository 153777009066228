import styled from 'styled-components';

const Wrap = styled.div.attrs({ 'className': 'wrap' })`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const Container = styled.div.attrs({ 'className': 'signup-form' })`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Right = styled.div`
  height: 100%;
  background: #fff;
  width: 75%;
  min-height: 60vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
`;

const Heading = styled.div`
  color: #000;
  font-size: 1.5rem;
  font-weight: 400;
  font-family: monospace;
  line-height: 2rem;
  padding: 0px 0px 1em;
`;

const PolicyText = styled.div`
  font-size: 0.8em;
  letter-spacing: 0.05em;
`;

export {
  Wrap,
  Container,
  HeadingContainer,
  Heading,
  PolicyText,
  Right,
};