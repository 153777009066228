import {
  StyledSectionTitle,
  StyledHeaderWrapper,
  StyledContentWrapper,
  StyledMainWrapper,
  StyledMainContainer,
  ParkingSessionAttributes,
} from "./style";
import { useEffect } from "react";
import { getParkingSessions } from "../../redux/hotelSlice";
import { useDispatch, useSelector } from "react-redux";
import ReactDataTable from "../ReactDataTable";
import { Link } from "react-router-dom";
import moment from "moment";
import Loader from '../../components/Loader';

const Dashboard = ({ view }) => {
  const dispatch = useDispatch();
  const guestList = useSelector(state => state?.hotel.data.parking_sessions);
  const { selectedLocation, locationLabel, loading, selectedGroup } = useSelector(state => state.hotel);
  useEffect(() => {
    if (typeof selectedLocation !== 'undefined' && selectedLocation !== 0) {
      dispatch(getParkingSessions({ selectedLocation }))
    }
  }, [dispatch, selectedLocation]);

  const guestData = guestList?.filter( guest => !guest?.expired && (guest?.operator_id === selectedGroup))

  const columns = [
    {
      id: "first_name",
      Header: "First Name",
      accessor: "first_name",
      Cell: (props) => props.original ? props.original?.first_name : "-"
    },
    {
      id: "last_name",
      Header: "Last Name",
      accessor: "first_name",
      Cell: (props) => props.original ? props.original?.last_name : "-"
    },
    {
      Header: "Room Number",
      accessor: "room_no",
      Cell: (props) => props.original ? `${props.original.room_no}` : '',
    },
    {
      Header: "License Plate Number",
      accessor: "vehicle_license_number",
      className: "text-uppercase"
    },
    {
      Header: "Parking Start Date",
      accessor: "parking_session_start_datetime",
      Cell: (props) => props.original ? moment(new Date(props.original?.parking_session_start_datetime)).format('YYYY-MM-DD') : ''
    },
    {
      Header: "Parking End Date",
      accessor: "parking_session_end_datetime",
      Cell: (props) => props.original ? moment(new Date(props.original?.parking_session_end_datetime)).format('YYYY-MM-DD') : ''
    },
    {
      Header: "Modified Date",
      accessor: "convert_time",
      Cell: (props) => props.original ? moment(new Date(props.original?.convert_details?.convert_time)).format('YYYY-MM-DD') : ''
    },
    {
      Header: "Session Type",
      accessor: "convert_type",
      Cell: (props) => props.original?.convert_details ? props.original?.convert_details?.convert_type : '-',
    },
    {
      Header: "Usage Day",
      accessor: "usage_days",
      Cell: (props) => props?.original?.usage_days === 0 ? 1 : props?.original?.usage_days
    },
    {
      Header: "Issued By",
      accessor: "user",
      Cell: (props) => props?.original?.user ? `${props.original?.user?.first_name} ${props.original?.user?.last_name}` : '-'
    },
    {
      Header: "List Price",
      accessor: "id",
      Cell: (props) => props?.original?.parking_amount ? `$ ${Number(props?.original?.parking_amount)?.toFixed(2)}` : '-'
    },
    {
      Header: "Status",
      accessor: "expired",
      className: "status",
      Cell: (props) =>
      (
        <span className={props?.original?.expired !== false ? "Active" : "Expired"}>
          <p>{props?.original?.expired !== false ? "Expired" : "Active"}</p>
        </span>
      ),
      filterable: false,
      sortable: false,
    },
    {
      Header: "Action",
      accessor: "id",
      Cell: (props) => (
        <ParkingSessionAttributes>
          <Link to={`/admin/session-details/${props?.original?.slug}/edit`}>
            <svg xmlns="http://www.w3.org/2000/svg" height="18" width="22" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>
          </Link>
        </ParkingSessionAttributes>
      ),
      filterable: false,
      sortable: false,
    },
  ];

  return (
    <StyledMainContainer view={view}>
      <StyledMainWrapper>
        <StyledHeaderWrapper>
          {locationLabel && <StyledSectionTitle>{`Parking List : ${locationLabel}`}</StyledSectionTitle>}
        </StyledHeaderWrapper>
      </StyledMainWrapper>
      <StyledContentWrapper>
        {loading ? <Loader /> : null}
        <ReactDataTable filterable={true} data={guestData} columns={columns} />
      </StyledContentWrapper>
    </StyledMainContainer>
  );
};

export default Dashboard;