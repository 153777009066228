import React, { useState } from "react";
import {
  StyledMainContainer,
  StyledFormWrapper,
  Logotext,
  FormWrapper,
  LoginButton,
  MainContainer,
} from "./style";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { authenticate } from "../../redux/authSlice";
import { toast } from "react-toastify";

const Login = () => {
  const [state, setState] = useState(false);
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.auth);

  return (
    <MainContainer>
      <StyledMainContainer>
        <div>
          <img
            src={require("../../assets/logo.png")}
            alt={"Logo"}
            className="logo-img"
          />
          <Logotext>Interstate Parking</Logotext>
        </div>
      </StyledMainContainer>
      <StyledFormWrapper>
        <div>
          <Formik
            initialValues={{ email: "", password: "", platform: 'hotel' }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "This field is required.";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              if (!values.password) {
                errors.password = "This field is required.";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              dispatch(authenticate(values)).unwrap().then(
                (res) => res.status !== 'ok' ? toast.error(res.message) : toast.success(res.message)
              ).catch(err => toast.error(err?.message));
              setSubmitting(false);
            }
            }
          >
            <Form>
              <FormWrapper>
                <label htmlFor="email" className="label">
                  Username
                </label>
                <Field
                  name="email"
                  type="email"
                  className="form-control"
                  placeholder="Enter Your Username"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="alert alert-danger"
                />
                <label htmlFor="password" className="label">
                  Password
                </label>
                <Field
                  name="password"
                  type={state === true ? "text" : "password"}
                  className="form-control"
                  placeholder="Enter Your Password"
                />
                {state === true ? (
                  <AiFillEyeInvisible
                    onClick={() => setState(!state)}
                    className="eye-icon"
                  />
                ) : (
                  <AiFillEye
                    onClick={() => setState(!state)}
                    className="eye-icon"
                  />
                )}
                <ErrorMessage
                  name="password"
                  component="div"
                  className="alert alert-danger"
                />
                <Field
                  name="platform"
                  type="hidden"
                  className="form-control"
                  value="hotel"
                />
                <LoginButton className="text-center py-5 ">
                  <button type="submit" disabled={loading}>Log In</button>
                </LoginButton>
              </FormWrapper>
            </Form>
          </Formik>
        </div>
      </StyledFormWrapper>
    </MainContainer>
  );
};
export default Login;
