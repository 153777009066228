import styled from "styled-components";


const StyledDashboardContainer = styled.div`
  height: 100%;

  @media (max-width: 991px) {
    display: block;
    width: 100%;
  }
`;

const StyledMainWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const StyledDashboardWrapper = styled.div`
  width: 99%;
  display: grid;
  gap: 1rem;

  @media (max-width: 1605px) {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
  .status .Expired {
    height: 15px;
    width: 15px;
    background-color: #38b000;
    border-radius: 50%;
    display: inline-block;
  }
  .Expired p {
    left: 20px;
    position: relative;
    font-size: 16px;
    font-weight: bolder;
    color: #38b000;
    top: -3px;
    letter-spacing: 0.1em;
  }
  .status .Active {
    height: 15px;
    width: 15px;
    background-color: #ff0000;
    border-radius: 50%;
    display: inline-block;
  }
  .Active p {
    left: 20px;
    position: relative;
    font-size: 16px;
    font-weight: bolder;
    color: #ff0000;
    top: -3px;
    letter-spacing: 0.09em;
  }
  .status {
    overflow-wrap: normal !important;
  }
`;

const ReportSelect = styled.div.attrs({className: 'reports-select-wrapper'})`
  @media (max-width: 512px) {
    display: block;
  }
`;

const SearchAndFilter = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 10px;
    align-items: center;
    .btn {
      margin-right: 5px;
      padding: 10px;
    }
    .css-b62m3t-container {
      width: 20rem;
    }
    @media (max-width: 740px) {
      display: block;
    }
    @media (max-width: 366px) {
      .transaction-filter{
        max-width: 15rem;
      }
    } 
`;

const ReportSelectDesc = styled.div`
  margin-right: 10px;
`;

const StyledHeaderWrapper = styled.div`
  width: 100%;
`;

// const StyledBreadCrumbs = styled.div`
//   height: 24px;
//   font-weight: 400;
//   font-size: 16px;
//   line-height: 24px;
//   display: flex;
//   align-items: center;
//   letter-spacing: 0.05em;
//   color: #72879f;

//   @media (max-width: 540px) {
//     font-size: 14px;
//   }
//   @media (max-width: 330px) {
//     font-size: 8px;
//   }

//   span {
//     color: #ffd100;
//   }

//   a {
//     color: #72879f;
//   }
//   a:hover {
//     color: #ffd100;
//   }
// `;

// const CsvButton = styled.button`
// background: #6C757E;
// border: none;
// height: 40px;
// width: 170px;
// border-radius: 5px;

// .csv-link {
//   text-decoration: none; 
//   color: #fff;
//   padding: 0 0.5rem;
//   border-radius: 5px;
// }
// @media (min-width: 444px) {
//   float: right;
// }
// @media (max-width: 443px) {
//   margin: 1rem 0 0 0; 
// }
// `;

const ParkingSessionAttributes = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 150px;
  width: 100%;

  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #72879f;
    opacity: 0.5;
  }
  a {
    color: #362e2e;
    display: block;
    word-break: break-word;
  }

  .operator-name-link {
    color: #72879f;
  }

  img.table-icon {
    width: 13px;
    margin-right: 12px;
  }
`;
const StyledSectionTitle = styled.h1`
  font-size: 40px;
  letter-spacing: 0.05em;
  color: #362e2e;
  margin: 10px 0;
  text-transform: capitalize;

  @media (max-width: 767px) {
    font-size: 34px;
    line-height: 42px;
    margin-bottom: 10px;
  }
  @media (max-width: 480px) {
    font-size: 28px;
    line-height: 36px;
  }
`;

export {
  StyledDashboardWrapper,
  StyledMainWrapper,
  StyledDashboardContainer,
  ReportSelect,
  ReportSelectDesc,
  SearchAndFilter,
  StyledHeaderWrapper,
  StyledSectionTitle,
  // StyledBreadCrumbs,
  // CsvButton,
  ParkingSessionAttributes
}
//   StyledBreadCrumbs,
//   CsvButton,