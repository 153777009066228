import './index.css';
import Dashboard from '../../components/Dashboard';


const AdminDashboard = () => {
  return (
    <>
      <Dashboard view={'dashboard-view'} />
    </>
  );
};

export default AdminDashboard;
