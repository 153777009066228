import PrimaryButton from "../../components/PrimaryButton";
import {
  StyledSectionTitle,
  StyledHeaderWrapper,
  StyledContentWrapper,
  StyledMainWrapper,
  StyledMainContainer,
  StyledBreadCrumbs,
  UsersAttributes,
  NoUserContentWrapper,
} from "./style";
import { useEffect, useState } from "react";
import { deleteUser, getUsers } from "../../redux/usersSlice";
import { useDispatch, useSelector } from "react-redux";
import ConfirmAlert from "../../components/ConfirmAlert/ConfirmAlert.js";
import deleteIcon from "../../assets/trash-solid.svg";
import editIcon from "../../assets/pen-to-square-solid.svg";
import { Link } from "react-router-dom";
import ReactDataTable from "../../components/ReactDataTable";
import { toast } from "react-toastify";
import Loading from '../../components/Loading/Loading';

const Users = () => {
  const dispatch = useDispatch();
  const { users, loading } = useSelector((state) => state?.users);
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [deleteUserDetails, setDeleteUserDetails] = useState({});
  const allUsers = users?.users || [];

  const deleteUserHandler = (id) => {
    setDeleteUserDetails(id);
    setShowConfirmAlert(true);
    setTimeout(() => {
      setShowConfirmAlert(false);
    }, 500);
  };

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const columns = [
    {
      id: "first_name",
      Header: "Name",
      accessor: "first_name",
      className: "name-column",
      Cell: (props) => (props.original
        ? props.original?.first_name + " " + props.original?.last_name
        : "-"
      )
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Platform",
      accessor: "platform",
    },
    {
      id: "roles",
      Header: "Role",
      accessor: (group) => group?.roles?.name,
      Cell: (props) => (props.original ? props.original?.roles?.[0]?.name : "-"),
    },
    {
      Header: "Action",
      accessor: "id",
      Cell: (props) => (
        <UsersAttributes>
          <Link to={`/admin/add-user/${props?.original?.id}/edit`}>
            <img className="table-icon" src={editIcon} alt="edit" />
          </Link>

          <Link to="#" >
            <img className="table-icon" src={deleteIcon} alt="delete" onClick={() => deleteUserHandler(props?.original?.id)} />
          </Link>
        </UsersAttributes>
      ),
      filterable: false,
    },
  ];

  return (
    <StyledMainContainer>
      <StyledMainWrapper>
        <StyledHeaderWrapper>
          <StyledSectionTitle>Users</StyledSectionTitle>
          <StyledBreadCrumbs>
            <span>User Listings</span>
            <PrimaryButton
              href="/admin/add-user"
              title="Add User"
              className="add-operator-btn"
            />
          </StyledBreadCrumbs>
        </StyledHeaderWrapper>
        <StyledContentWrapper>
          {showConfirmAlert ? (
            <ConfirmAlert
              confirmMessage="Are you sure you want to delete user?"
              buttonYesEvent={() =>
                dispatch(deleteUser(deleteUserDetails))
                  .unwrap()
                  .then((result) =>
                    result.status !== "success"
                      ? toast.error(result.message)
                      : toast.success(result.message)
                  )
              }
            />
          ) : null}
          {loading
            ? <Loading />
            : allUsers.length === 0
              ? <NoUserContentWrapper><p>You do not have any users on your list.</p></NoUserContentWrapper>
              :
              <ReactDataTable data={allUsers} columns={columns} filterable={true} />
          }
        </StyledContentWrapper>
      </StyledMainWrapper>
    </StyledMainContainer>
  );
};

export default Users;
