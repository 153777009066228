/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';


export const DashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    loading: false,
    error: null,
    data: [],
    showLeftBar: false,
  },
  reducers: {
    toggleLeftBar: (state) => ({
      ...state,
      showLeftBar: !state.showLeftBar,
    }),
  },
});

export const { toggleLeftBar } = DashboardSlice.actions;

export default DashboardSlice.reducer;
