import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Inflow from '@plecco/inflow';

export const createUser = createAsyncThunk(
    'users/createUser',
    async ({ email, firstName, lastName, contactno, platform, userId }) => {
        try {
            const response = await Inflow.createUserService({ email, firstName, lastName, contactno, platform, userId });
            return response?.data;
        } catch (e) {
            return e.response?.data;
        }
    }
);

export const getUser = createAsyncThunk(
    'users/getUser',
    async (userId) => {
        const response = await Inflow.getUser(userId);
        const data = response?.data;
        return data;
    }
);

export const getUsers = createAsyncThunk(
    'users/getUsers',
    async () => {
        const response = await Inflow.getUsers();
        const data = response?.data;
        return data;
    }
);

export const deleteUser = createAsyncThunk(
    'users/deleteUser',
    async (userId) => {
        try {
            const response = await Inflow.deleteUser(userId);
            const data = response?.data;
            data['userId'] = userId;
            return data;
        } catch (error) {
            return error.response.data;
        }
    }
)

export const userSlice = createSlice({
    name: 'users',
    initialState: {
        loading: false,
        error: null,
        data: [],
        user: [],
        users: [],
    },
    extraReducers: {
        [createUser.pending]: (state) => {
            state.loading = true;
        },
        [createUser.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.error?.message
        },
        [createUser.fulfilled]: (state, action) => {
            state.loading = false;
            state.data.push(action.payload?.data);
        },
        [getUser.pending]: (state) => {
            state.loading = true;
        },
        [getUser.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.error?.message;
        },
        [getUser.fulfilled]: (state, action) => {
            state.loading = false;
            state.user = action.payload;
        },
        [getUsers.pending]: (state) => {
            state.loading = true;
        },
        [getUsers.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        },
        [getUsers.fulfilled]: (state, action) => {
            state.loading = false;
            state.users = action.payload;
        },
        [deleteUser.pending]: (state) => {
            state.loading = true;
        },
        [deleteUser.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        },
        [deleteUser.fulfilled]: (state, action) => {
            state.loading = false;
            state.users.users = Object.entries(state.users)[0][1].filter((user) => user.id !== action.payload.userId);
        },
    },
})

export default userSlice.reducer;