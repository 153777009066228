import { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import CloseButton from "react-bootstrap/CloseButton";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import format from "date-fns/format";
import { CalendarWrap } from "./style";

const ReactDateRange = ({ setDateFilterRange }) => {
  const date = new Date();
  const [range, setRange] = useState([
    {
      startDate: new Date(date.getFullYear(), date.getMonth(), 1),
      endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
      key: "selection",
    },
  ]);
  const [open, setOpen] = useState(false);
  const handelChange = (item) => {
    setRange([item.selection]);
    setDateFilterRange([item.selection]);
  };
  useEffect(() => {
    setDateFilterRange(range);
  }, [setDateFilterRange, range])


  return (
    <CalendarWrap>
      <input
        value={`${format(range[0].startDate, "MM/dd/yyyy")} to ${format(
          range[0].endDate,
          "MM/dd/yyyy"
        )}`}
        placeholder="Select Date Range"
        readOnly
        onClick={() => setOpen((open) => !open)}
      />
      <CloseButton onClick={() => setOpen(false)} />

      {open && (
        <DateRangePicker
          onChange={handelChange}
          editableDateInputs={true}
          moveRangeOnFirstSelection={false}
          ranges={range}
          months={2}
          direction="vertical"
          className="drp-design"
        />
      )}
    </CalendarWrap>
  );
};

export default ReactDateRange;
