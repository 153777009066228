import styled from 'styled-components';

const StyledMenuItem = styled.div`
  padding: 10px 30px;
  display: flex;
  justify-content: left;
  gap: 20px;

  a {
    text-decoration: none;
    font-size: 1rem;
    color: #717579;
    font-weight: 500;
    margin-left: 1.25rem;
  }
  :active {
    color: #72879f;
  }

  // a.settings-text:active, a.settings-text:hover {
  //   color: #f8857d;
  // }
  .menu-icons-svg svg{
    max-height: 1.5rem;
    max-width: 1.5rem;
    height: 100%;
    fill: #b6b6b6;
    cursor: pointer;
  }
`;

const SelectWrapper = styled.div.attrs({className: 'select-wrapper'})`
  padding: 10px 30px;
  .select-dropdown {
    margin-bottom: 5px;
  }
  .loc-text{
    font-size: 1.25rem;
    font-weight: 700;
    letter-spacing: .1rem;
  }
`;

const SidebarWrapper = styled.div.attrs({ className: 'deznav' })`
    .metismenu > li > a svg {
      margin-right: 1.25rem;
    }
    .metismenu .mm-active {
      color: #f8857d;
    }
    .metismenu .mm-active svg {
      fill: #f8857d !important;
    }
    @media only screen and (max-width: 87.5rem) {
      .deznav .metismenu>li {
        padding: 0 0.625rem !important;
      }
    }
`;

export {
  StyledMenuItem,
  SelectWrapper,
  SidebarWrapper
};
