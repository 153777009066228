import styled from "styled-components";

const StyledSectionTitle = styled.div`
  font-weight: 300;
  font-size: 40px;
  letter-spacing: 0.05em;
  color: #362e2e;
  margin: 10px 0;

  @media (max-width: 767px) {
    font-size: 34px;
    line-height: 42px;
    margin-bottom: 10px;
  }
  @media (max-width: 480px) {
    font-size: 28px;
    line-height: 36px;
  }
`;

const StyledHeaderWrapper = styled.div`
  width: 98%;
`;

const StyledBreadCrumbs = styled.div`
  height: 24px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  letter-spacing: 0.05em;
  color: #72879f;
  margin: 10px 6px;

  span {
    color: #ffd100;
  }

  a {
    color: #72879f;
  }
  a:hover {
    color: #ffd100;
  }
  @media (max-width: 540px) {
    font-size: 13px;
  }
`;

const SearchInputAndAddUser = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;

  @media (max-width: 540px) {
    display: block;
    margin: 10px 0 5px;
  }

  input {
    border: 1px solid #ffd100;
    border-radius: 4px;
    padding: 9px 4px 9px 40px;
    font-weight: 400;
    color: #72879f;
    outline: none;
    background: transparent
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
      no-repeat 13px center;

    @media (max-width: 540px) {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  input::placeholder {
    color: #b5b7c0;
    opacity: 1;
  }

  .add-operator-btn {
    display: inline-block;
    @media (max-width: 776px) {
      width: auto;
    }
  }
`;

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: #fff;
  padding: 20px;
  width: 100%;
  min-height: 72vh;
  border-radius: 5px;
  .rt-td.name-column a {
    color:  #72879f;
  }
`;

const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 991px) {
    width: 100%;
  }
  box-shadow: 0px 0px 3px rgb(0 0 0 / 25%);
  background: #fff;
`;

const StyledMainContainer = styled.div`
  @media (max-width: 991px) {
    display: flex;
  }

  @media (max-width: 767px) {
    overflow-y: auto;
  }
`;

const UsersAttributes = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 150px;
  width: 100%;

  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #72879f;
    opacity: 0.5;
  }
  a {
    color: #362e2e;
    display: block;
    word-break: break-word;
  }

  .operator-name-link {
    color: #72879f;
  }

  img.table-icon {
    width: 13px;
    margin-right: 12px;
  }
`;

const NoUserContentWrapper = styled.div`
  text-align: center;
  font-size: 20px;
`;

export {
  StyledSectionTitle,
  StyledHeaderWrapper,
  SearchInputAndAddUser,
  StyledContentWrapper,
  StyledMainWrapper,
  StyledMainContainer,
  StyledBreadCrumbs,
  UsersAttributes,
  NoUserContentWrapper,
};
