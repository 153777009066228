import styled from "styled-components";

const CalendarWrap = styled.div`
    display: inline-block;
    position: relative;

    input {
        padding: 5px 8px 4px 8px;
        border-radius: 3px;
        border: 1px solid #666;
        @media (max-width: 366px) {
            max-width: 15rem;
        }
    }

    .drp-design {
        position: absolute;
        top: 35px;
        right: 0px;
        border: solid 1px #eff2f7;
        z-index: 1;
    }
`;

export { CalendarWrap };
