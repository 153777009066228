import { combineDateAndTime, daysOfTheWeek } from "../utils"

export const useRates = (hotelRates) => {
  const currentDateTime = new Date();
  const activerates = hotelRates ? hotelRates?.filter(rate => rate.active) : [];

  const getRateEndDateTime = (rate, checkOutDate) => {
    let endDateTime;
    switch (rate?.rate_type) {
      case "validBetween":
        endDateTime = validBetween(rate, checkOutDate);
        break;
      case "validUntil":
        endDateTime = validUntil(rate, checkOutDate);
          break;
      default:
        endDateTime = checkOutDate;
    }
    return endDateTime;
  }

  const isValidRecurringRate = (rate) => {
    const startDateTime = new Date(rate.available_start);
    const endDateTime = new Date(rate.available_end);
    let day = currentDateTime.getDay();
    day = daysOfTheWeek(day);
    const startTime = combineDateAndTime(currentDateTime, startDateTime);
    const endTime = combineDateAndTime(currentDateTime, endDateTime);
    startTime > endTime && endTime.setDate(endTime.getDate() + 1);
    const availableForPurchase = rate?.available_for_purchase.includes(day);
    const validTime = startTime <= currentDateTime && endTime >= currentDateTime;
    return availableForPurchase && validTime;
  }

  const isValidEventRate = (rate) => {
    const startDateTime = new Date(rate.available_start);
    const endDateTime = new Date(rate.available_end);
    const validTimeWindow = startDateTime <= currentDateTime && endDateTime >= currentDateTime;
    if(validTimeWindow){
      let day = currentDateTime.getDay();
      day = daysOfTheWeek(day);
      const availableForPurchase = rate?.available_for_purchase.includes(day);
      const startDateTime = new Date(rate.available_start);
      const endDateTime = new Date(rate.available_end);
      const dailyStartDateTime = combineDateAndTime(currentDateTime, startDateTime);
      const dailyEndDateTime = combineDateAndTime(currentDateTime, endDateTime);
      const dailyValidTimeWindow = dailyStartDateTime <= currentDateTime && dailyEndDateTime >= currentDateTime;
      return availableForPurchase && dailyValidTimeWindow;
    }
  };

  // const validEventRate = rate => rate?.available_type === "event" && isValidEventRate(rate);
  // const validPermitRates = rate => rate?.available_type === "permit" && isValidEventRate(rate);
  // const validRecurringRate = rate => rate.available_type === "recurring" && isValidRecurringRate(rate);

  // const rateIsAvailableForPurchase = (rate) => validEventRate(rate) || validRecurringRate(rate) || validPermitRates(rate);

  const validUntil = (rate, checkOutDate) => {
    const endDateTime = combineDateAndTime(checkOutDate, new Date(rate?.rate_end));
    return endDateTime
  }

  const validBetween = (rate, checkOutDate) => {
    const endDateTime = combineDateAndTime(checkOutDate, new Date(rate?.rate_end));
    return endDateTime;
  }

  const validEventRates = activerates?.filter(rate => rate?.available_type === "event" && isValidEventRate(rate));

  const validPermitRates = activerates?.filter(rate => rate?.available_type === "permit" && isValidEventRate(rate));

  const validRecuringRates = activerates?.filter(rate => rate?.available_type === "recurring" && isValidRecurringRate(rate));

  const mergedRates = [...validRecuringRates, ...validPermitRates, ...validEventRates];
  const ratesOptions = mergedRates.map((rate) => ({
    ...rate,
    label:`${rate?.rate_name} - $${Number(rate?.price).toFixed(2)}`,
    value: rate.id
  }));

  return { getRateEndDateTime, ratesOptions }
};
