import React, { useEffect, useState } from "react";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import {
  StyledWrapper,
  FormikSectionContainer,
  FormWrapper,
  FormGroup,
  FormGroupWrapper,
  StyledMainWrapper,
} from "./style";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { LogoText, StyledMainContainer, LoginButton } from "./style";
import { purchaseParking, getParkingInfo } from "../../redux/hotelSlice";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import Input from "react-phone-number-input/input";
import "react-phone-number-input/style.css";
import { useRates } from "../../hooks";
import { getDateDifferenceInDays } from "../../utils";
import Loader from "../../components/Loader";
import Select from "react-select";

const NewParkingSession = () => {
  const parkingSession = useSelector((state) => state.hotel.data);
  const hotelData = useSelector((state) => state.hotel);
  const {
    locationLabel,
    parkingSessionLoading,
    loading,
    hotelRates,
    selectedGroup,
  } = hotelData;
  const filteredHotelRates = hotelRates?.filter(
    (rate) => rate?.operator_id === selectedGroup
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { parkingId } = useParams();
  const { getRateEndDateTime, ratesOptions = [] } =
    useRates(filteredHotelRates);
  const [selectedRate, setSelectedRate] = useState(null);
  useEffect(() => {
    if (typeof parkingId !== "undefined") {
      dispatch(getParkingInfo(parkingId));
    }
  }, [dispatch, parkingId]);

  useEffect(() => {
    const { selectedLocation, hotelRates } = hotelData;
    if (selectedLocation) {
      if (!selectedLocation) {
        toast.error("Location field is required.");
        return;
      }
      if (!hotelRates?.length) {
        toast.error("No rate configured. Please contact admin.");
        return;
      }
      if (!ratesOptions?.length) {
        toast.error(
          "The hotel rate configured is not available for purchase at the moment. Please contact admin."
        );
        return;
      }
    }
    // eslint-disable-next-line
  }, [hotelData]);

  let defaultInitialValues = {
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    room_no: "",
    vehicle_license_number: "",
    checkInDate: moment(new Date()).format("YYYY-MM-DD"),
    checkOutDate: "",
    rate: ratesOptions.length === 1 ? ratesOptions[0] : null,
  };
  if (typeof parkingId !== "undefined" && parkingId !== null) {
    defaultInitialValues = {
      email: parkingSession.email !== null ? parkingSession.email : "",
      first_name:
        parkingSession.first_name !== null ? parkingSession.first_name : "",
      last_name:
        parkingSession.last_name !== null ? parkingSession.last_name : "",
      phone_number:
        parkingSession.phone_number !== null ? parkingSession.phone_number : "",
      room_no: parkingSession.room_no !== null ? parkingSession.room_no : "",
      vehicle_license_number:
        parkingSession.vehicle_license_number !== null
          ? parkingSession.vehicle_license_number
          : "",
      //checkInDate: parkingSession.checkInDate !== null ? moment(parkingSession.parking_session_start_datetime).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
      checkInDate:
        parkingSession.checkInDate !== null
          ? moment(
              new Date(parkingSession.parking_session_start_datetime)
            ).format("YYYY-MM-DD")
          : moment(new Date()).format("YYYY-MM-DD"),
      //checkOutDate: parkingSession.checkOutDate !== null ? moment(parkingSession.parking_session_end_datetime).format('YYYY-MM-DD') : null,
      checkOutDate:
        parkingSession.checkOutDate !== null
          ? moment(
              new Date(parkingSession.parking_session_end_datetime)
            ).format("YYYY-MM-DD")
          : null,
      rate: parkingSession?.parking_session_hotel?.rate
        ? {
            ...parkingSession?.parking_session_hotel?.rate,
            label: `${
              parkingSession?.parking_session_hotel?.rate?.rate_name
            } - $${Number(
              parkingSession?.parking_session_hotel?.rate?.price
            ).toFixed(2)}`,
            value: parkingSession?.parking_session_hotel?.rate.id,
          }
        : ratesOptions.find(
            (rate) =>
              rate?.id === parkingSession?.parking_session_hotel?.rate_id
          ),
    };
  }
  let [initialValues, setInitialValues] = useState(defaultInitialValues);

  useEffect(() => {
    if (parkingSession && !loading) {
      const rate = parkingSession?.parking_session_hotel?.rate
        ? {
            ...parkingSession?.parking_session_hotel?.rate,
            label: `${
              parkingSession?.parking_session_hotel?.rate?.rate_name
            } - $${Number(
              parkingSession?.parking_session_hotel?.rate?.price
            ).toFixed(2)}`,
            value: parkingSession?.parking_session_hotel?.rate.id,
          }
        : ratesOptions.length === 1
        ? ratesOptions[0]
        : null;
      setSelectedRate(rate);
      setInitialValues((initialValues) => ({
        ...initialValues,
        rate: rate,
      }));
    }
  }, [parkingSession, loading, ratesOptions?.length]);

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("This field is required."),
    last_name: Yup.string().required("This field is required."),
    phone_number: Yup.string()
      .min(12, "Phone number is too short")
      .max(12, "Phone number is too long"),
    room_no: Yup.string().required("This field is required."),
    vehicle_license_number: Yup.string().required("This field is required."),
    checkInDate: Yup.date().required("This field is required."),
    checkOutDate: Yup.date().required("This field is required."),
    rate: Yup.object().required("This field is required."),
  });

  const getCurrentDateTime = (date) => {
    const [year, month, day] = date.split("-");
    const localDate = new Date(year, month - 1, day);
    const currentDateTime = new Date();
    const hours = currentDateTime.getHours();
    const minutes = currentDateTime.getMinutes();
    const seconds = currentDateTime.getSeconds();
    const milliseconds = currentDateTime.getMilliseconds();
    localDate.setHours(hours, minutes, seconds, milliseconds);
    return localDate;
  };

  const handleSubmitHandler = (formValue) => {
    const {
      checkInDate,
      checkOutDate,
      phone_number,
      email,
      first_name,
      last_name,
      vehicle_license_number,
      room_no,
      rate,
    } = formValue;
    const { selectedLocation, hotelRates, selectedGroup } = hotelData;
    if (!selectedLocation) {
      toast.error("Location field is required.");
      return;
    }
    if (!hotelRates?.length) {
      toast.error("No rate configured. Please contact admin.");
      return;
    }
    if (!ratesOptions?.length) {
      toast.error(
        "The hotel rate configured is not available for purchase at the moment. Please contact admin."
      );
      return;
    }
    const differenceInDays = getDateDifferenceInDays(checkInDate, checkOutDate);
    const totalAmount = Number(selectedRate?.price);
    const parking_amount = differenceInDays
      ? totalAmount * differenceInDays
      : totalAmount;
    dispatch(
      purchaseParking({
        parkingId,
        parking_session_start_datetime: getCurrentDateTime(checkInDate),
        parking_session_end_datetime: getRateEndDateTime(
          selectedRate,
          moment(checkOutDate).toDate()
        ),
        vehicle_license_number: vehicle_license_number.toUpperCase(),
        parking_amount: parking_amount,
        location_id: selectedLocation,
        currency: "usd",
        phone_number,
        first_name,
        last_name,
        email,
        room_no,
        unit_price: Number(selectedRate?.price),
        operator_id: selectedGroup,
        rate,
      })
    )
      .unwrap()
      .then((data) => {
        if (data?.parking_session) {
          toast.success("Parking session successfully created!");
          navigate(`/admin`);
          setInitialValues({
            email,
            first_name,
            last_name,
            phone_number,
            room_no,
            vehicle_license_number,
            checkOutDate,
            rate,
          });
        } else {
          toast.error(
            data?.message ||
              "Parking session failed to be created!. Please contact support."
          );
        }
      })
      .catch((err) => {
        toast.error(
          err?.message ||
            "Parking session failed to be created!. Please contact support."
        );
      });
  };

  return (
    <StyledWrapper>
      {parkingSessionLoading || loading ? <Loader /> : null}
      <StyledMainWrapper>
        <FormikSectionContainer>
          <Formik
            enableReinitialize
            initialValues={
              typeof parkingId !== "undefined"
                ? defaultInitialValues
                : initialValues
            }
            validationSchema={validationSchema}
            onSubmit={handleSubmitHandler}
          >
            {({ values, setFieldValue }) => (
              <Form>
                {document
                  .querySelector(".alert")
                  ?.scrollIntoView({ behavior: "smooth", block: "center" })}
                <FormWrapper>
                  <StyledMainContainer>
                    <div>
                      <img
                        src={require("../../assets/logo.png")}
                        alt={"Logo"}
                        className="logo-img"
                      />
                      <LogoText>
                        {locationLabel
                          ? `Interstate Parking ${locationLabel}`
                          : `Interstate Parking`}
                      </LogoText>
                    </div>
                  </StyledMainContainer>
                  <FormGroupWrapper>
                    <FormGroup>
                      <label htmlFor="first_name">Guest First Name</label>
                      <Field
                        name="first_name"
                        type="text"
                        className="form-control"
                        placeholder="Enter Guest First Name"
                      />
                      <ErrorMessage
                        name="first_name"
                        component="div"
                        className="alert alert-danger"
                      />
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor="last_name">Guest Last Name</label>
                      <Field
                        name="last_name"
                        type="text"
                        className="form-control"
                        placeholder="Enter Guest Last Name"
                      />
                      <ErrorMessage
                        name="last_name"
                        component="div"
                        className="alert alert-danger"
                      />
                    </FormGroup>
                  </FormGroupWrapper>
                  <FormGroup className="mb-2">
                    <label htmlFor="email">Guest Email Address</label>
                    <Field
                      name="email"
                      type="email"
                      className="form-control"
                      placeholder="Enter Guest Email Address"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="alert alert-danger"
                    />
                  </FormGroup>
                  <FormGroupWrapper>
                    <FormGroup>
                      <label htmlFor="phone_number">Mobile Number</label>
                      <Input
                        id="phone_number"
                        name="phone_number"
                        country="US"
                        className="form-control"
                        placeholder="(xxx) xxx-xxxx"
                        maxLength={14}
                        value={defaultInitialValues?.phone_number}
                        onChange={(number) =>
                          setFieldValue("phone_number", number)
                        }
                      />
                      <ErrorMessage
                        name="phone_number"
                        component="div"
                        className="alert alert-danger"
                      />
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor="room_no">Room No.</label>
                      <Field
                        name="room_no"
                        type="number"
                        className="form-control"
                        placeholder="Enter Your Room No"
                        onWheel={(e) => e.target.blur()}
                      />
                      <ErrorMessage
                        name="room_no"
                        component="div"
                        className="alert alert-danger"
                      />
                    </FormGroup>
                  </FormGroupWrapper>
                  <FormGroup className="mb-2">
                    <label htmlFor="vehicle_license_number">
                      Enter Plate Number
                    </label>
                    <Field
                      name="vehicle_license_number"
                      type="string"
                      className="form-control"
                      placeholder="Enter Plate Number"
                      value={values?.vehicle_license_number
                        ?.replace(/[^0-9a-zA-Z]/g, "")
                        ?.toUpperCase()}
                    />
                    <ErrorMessage
                      name="vehicle_license_number"
                      component="div"
                      className="alert alert-danger"
                    />
                  </FormGroup>
                  <FormGroupWrapper>
                    <FormGroup>
                      <label htmlFor="checkInDate">Check-In Date</label>
                      <Field
                        name="checkInDate"
                        type="date"
                        min={
                          typeof parkingId === "undefined"
                            ? moment(new Date()).format("YYYY-MM-DD")
                            : ""
                        }
                        className="form-control  date-picker"
                        placeholder="Check-out Date"
                        value={values?.checkInDate}
                        disabled={
                          typeof parkingId !== "undefined" &&
                          values?.checkInDate <
                            moment(new Date()).format("YYYY-MM-DD")
                            ? true
                            : false
                        }
                      />
                      <ErrorMessage
                        name="checkInDate"
                        component="div"
                        className="alert alert-danger"
                      />
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor="checkOutDate">Check-out Date</label>
                      <Field
                        name="checkOutDate"
                        type="date"
                        min={values?.checkInDate}
                        className="form-control date-picker"
                        placeholder="Check-out Date"
                      />
                      <ErrorMessage
                        name="checkOutDate"
                        component="div"
                        className="alert alert-danger"
                      />
                    </FormGroup>
                  </FormGroupWrapper>
                  <FormGroup>
                    <label htmlFor="rate">Select Rate: </label>
                    <Select
                      className="select-dropdown"
                      options={ratesOptions}
                      classNamePrefix="borderCSS"
                      defaultValue={defaultInitialValues.rate}
                      value={
                        selectedRate || ratesOptions?.length === 1
                          ? ratesOptions[0]
                          : null
                      }
                      name="rate"
                      onChange={(rate) => {
                        setFieldValue("rate", rate);
                        setSelectedRate(rate);
                      }}
                      placeholder="Select rate"
                      style={{ borderRadius: "10px" }}
                    />
                    <ErrorMessage
                      name="rate"
                      component="div"
                      className="alert alert-danger"
                    />
                  </FormGroup>
                  <LoginButton className="text-center py-5">
                    <button
                      disabled={parkingSessionLoading}
                      className="btn btn-primary"
                      type="submit"
                    >
                      Submit
                    </button>
                  </LoginButton>
                </FormWrapper>
              </Form>
            )}
          </Formik>
        </FormikSectionContainer>
      </StyledMainWrapper>
    </StyledWrapper>
  );
};
export default NewParkingSession;
