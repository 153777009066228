import styled from "styled-components";

const StyledWrapper = styled.div.attrs({ className: "styled-wrapper" })`
  min-height: 100vh;
  display: grid;

  @media only screen and (max-width: 991px) {
    display: flex;
  }
  @media only screen and (max-width: 450px) {
    display: block;
  }
`;
const StyledMainWrapper = styled.div`
  margin: auto;
  
`;

const FormikSectionContainer = styled.div.attrs({
  className: "formkit-section-container",
})`
  margin: auto;
  @media (max-width: 991px) {
    height: 100%;    
    margin-bottom: 0px;
  }
  @media (max-width: 767px) {
    max-width: 100%;
  }
`;

const WelcomeContainer = styled.div.attrs({ className: "welcome-container" })`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 15%;
`;

const FormikSection = styled.div.attrs({ className: "formkit-section" })`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

const AddUserContainer = styled.div`
  width: 100%;
  .form-group-wrapper {
    flex-direction: row;
    gap: 1rem;
  }
`;

const AddUserHeading = styled.div`
  font-size: 32px;
  line-height: 40px;
  text-align: left;
  display: block;
  width: 100%;
  margin-bottom: 20px;

  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const SelectFieldContainer = styled.div`
  display: flex;
  gap: 1rem;

  label {
    color: #555;
    font-weight: 500;
    font-style: normal;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }
`;

const FormWrapper = styled.div.attrs({ className: "form-wrapper card" })`
  position: relative;
  max-width: 32rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px 50px 0 50px;
  @media (max-width: 450px) {
    padding: 8px 30px 0 30px;
  }

  > div {
    @media (max-width: 480px) {
      display: block;
    }
    > div {
      position: relative;
      margin-bottom: 10px;
      width: 49%;

      @media (max-width: 480px) {
        width: 100%;
      }
    }
  }
  .alert {
    padding: 5px 0px;
    margin: unset;
    border: none;
    background: none;
    color: red;
  }

  button {
    margin-bottom: 10px;
    @media (max-width: 480px) {
      margin: 0 auto 10px;
    }
  }
  @media (max-width: 550px) {
    top: 0;
    bottom: 0;
  }
`;

const FormGroup = styled.div.attrs({ className: "form-group" })`
  label {
    color: #555;
    font-weight: 500;
    font-style: normal;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }

  input {
    font-size: 0.8rem;
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
  .select-dropdown{
     width: 100%;
     .borderCSS__control {
       border-radius: 0.5rem;
     }
     input {
       padding: 12px 12px 12px 16px !important;
     }
  }
`;

const TermsAndConditionWrapper = styled.div.attrs({ className: "form-group" })`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  label {
    font-size: 12px;

    @media (max-width: 480px) {
      font-size: 10px;
      position: relative;
      top: -3px;
    }
  }
`;

const StyledMainContainer = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  @media (max-width: 400px) {
    .logo-img {
      margin-top: 28%;
    }
  }
`;

const LogoText = styled.h2`
  text-transform: capitalize;
  color: black;
  font-size: 1.2rem;
`;

const LoginButton = styled.div`
  button {
    border: none;
    color: #fff;
    text-shadow: 0 0 #fff;
    padding: 0.7rem 9.2rem;
    border-radius: 5px;
    @media (max-width: 450px) {
      padding: 0.7rem 7.8rem;
    }
    @media (max-width: 380px) {
      padding: 0.7rem 7rem;
    }
    @media (max-width: 320px) {
      padding: 0.7rem 5rem;
    }
  }
`;
const FormGroupWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const FormField = styled.div`
  clear: both;
  margin: 0 0 1em;
  width: 100%;

  .error-label {
    color: #d90429;
    font-size: 14px;
    font-weight: 700;
  }

  .double-field {
    margin-right: 30px;
  }

  label {
    align-items: center;
    display: flex;
    color: #555;
    font-weight: 500;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }

  input {
    width: 100%;
    display: flex;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    position: relative;
    padding: 13px 15px;
    text-transform: uppercase;
    background: #fff;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: 0.28571429rem;
    box-shadow: inset 0 0 0 0 transparent;
    color: rgba(0,0,0,.87);
    font-size: 1em;
    margin: 0;
    outline: 0;
    padding: 0.67857143em 1em;
    transition: color .1s cubic-bezier(0.4, 0.18, 0.25, 1),border-color .1s ease;
    vertical-align: top;
    margin-bottom: 8px;
  }

  select {
    width: 100%;
    display: flex;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    position: relative;
    padding: 13px 15px;
    text-transform: uppercase;
    background: #fff;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: 0.28571429rem;
    box-shadow: inset 0 0 0 0 transparent;
    color: rgba(0,0,0,.87);
    font-size: 1em;
    margin: 0;
    outline: 0;
    padding: 0.67857143em 1em;
    transition: color .1s cubic-bezier(0.4, 0.18, 0.25, 1),border-color .1s ease;
    vertical-align: top;
    margin-bottom: 8px;
  }
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    border: none;
    height: 42px;
  }
  .css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0px !important;
  }
  .css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator {
    margin-top: -10px;
  }
  .MuiButtonBase-root.MuiAutocomplete-clearIndicator{
    visibility: visible;
  }
`;

export {
  FormWrapper,
  StyledWrapper,
  StyledMainWrapper,
  FormikSectionContainer,
  WelcomeContainer,
  FormikSection,
  FormGroup,
  TermsAndConditionWrapper,
  AddUserHeading,
  AddUserContainer,
  SelectFieldContainer,
  StyledMainContainer,
  LogoText,
  LoginButton,
  FormGroupWrapper,
  FormField,
};
