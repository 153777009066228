import React from 'react';
import { Container, Wrap, HeadingContainer, Heading, Right, PolicyText } from './style';

const TermsAndCondition = ({ close }) => {
    return (
        <Wrap>
            <Container>
                <Right>
                    <HeadingContainer>
                        <Heading>Terms & Conditions</Heading>
                    </HeadingContainer>
                    <PolicyText>
                        <article>
                            <p>Charges are for the use of parking space only. Interstate Parking, LLC assumes no responsibility whatsoever for loss or damage due to fire, theft, collision or otherwise to the vehicle or its contents however caused.</p>
                            <p>By parking your vehicle on this parking lot,</p>
                            <ol>
                                <li>
                                    You accept and agree to all posted terms, conditions, and requirements for parking,
                                </li>
                                <li>
                                    You agree to pay all applicable fees, charges and violation charges, up to $150.00, and
                                </li>
                                <li>
                                    You understand that this contract may affect your legal rights.
                                </li>
                            </ol>

                        </article>
                        <article>
                            <p>Do not park your vehicle on this lot if you do not agree to these contract terms. All posted parking fees must be paid in advance. If you</p>
                            <ol>
                                <li>
                                    Do not display an authorized permit,
                                </li>
                                <li>
                                    Fail to pay the posted parking fee in advance,
                                </li>
                                <li>
                                    Exceed the posted time limit for parking your vehicle, OR
                                </li>
                                <li>
                                    Improperly park your vehicle in any manner, you agree that the parking operator may impound/immobilize your vehicle.  You further agree that the parking operator may assess the Parking Violation Charge up to $150.00, and that if you fail to pay any assessed fee or charge within 15 days the parking operator reserves the right to refer unpaid accounts to collection.
                                </li>

                            </ol>
                        </article>
                        <p>
                            By <a href='#/' onClick={() => close(false)} >parking here</a>, you grant permission to Interstate Parking, LLC and its agents to obtain from the applicable state authority the registered owner information, including name and address for the vehicle you are driving for the purpose of collecting unpaid parking and/or violation fees.
                        </p>
                    </PolicyText>
                </Right>
            </Container>
        </Wrap>
    )
}

export default TermsAndCondition