import styled from 'styled-components';

const StyledWrapper = styled.div.attrs({ 'className': 'styled-wrapper' })`
  display: grid;
  height:100%;
  min-height: 95vh;
`;

const FormikSectionContainer = styled.div.attrs({ 'className': 'formkit-section-container' })`
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 0.5rem;  
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width:90%;
  background-color: #fff;
  height: 100%;
  margin: auto;

  @media (max-width:991px){
    height: 100%;
    margin-bottom:80px;
  }
  @media (max-width:767px){
    max-width:100%;
  }
`;


const WelcomeContainer = styled.div.attrs({ 'className': 'welcome-container' })`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 15%;

  > .welcome {
    font-size: 1rem;
    color: #8d8d8d;
    font-weight: 400;
    font-style: normal;
    line-height: 1.6rem;
  }

  > .signup-account {
    font-size: 1.125rem;
    color: #000;
    font-weight: 700;
    font-style: normal;
    line-height: 1.7rem;
  }
`;

const FormikSection = styled.div.attrs({ 'className': 'formkit-section' })`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
 
  @media (max-width:991px){
    width:100%;
    padding:0 20px;
  }
  @media (max-width:400px){
    padding:0 15px;
  }
`;

const AddUserContainer = styled.div`
    width:100%;
    .form-group-wrapper {
      flex-direction: row;
      gap:1rem;
    }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
`;

const AddUserHeading = styled.div`
  font-size:32px;
  line-height:40px;
  text-align: left;
  display: block;
  width: 100%;
  margin-bottom: 20px;

  @media (max-width:991px){
    padding:0 20px;
  }
`;

const SelectFieldContainer = styled.div`
   display:flex;
   gap:1rem;
   
   label {
    color: #555;
    font-weight: 500;
    font-style: normal;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
   }
`;

const FormWrapper = styled.div.attrs({ 'className': 'form-wrapper' })`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  
  > div {
      @media (max-width:480px){
        display: block;
      }
       > div {
        position:relative;
        margin-bottom: 10px;
        width:49%;

        @media (max-width:480px){
          width:100%;
        }
       }
  }
  .alert {
    padding:5px 0px;
    margin:unset;
    border:none;
    background:none;
    color:red;
  }

  button {
    margin-bottom:10px;
    @media (max-width:480px){
      margin: 0 auto 10px;
    }
  }
`;

const FormGroup = styled.div.attrs({ 'className': 'form-group' })`
  
  > button {
    width: 100%;
    background-color: #fbca00;
    border-radius: 6px;
    padding: 0.5rem;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 25px;
    border: none;    
  }
`;

const TermsAndConditionWrapper = styled.div.attrs({ 'className': 'form-group' })`
  display:flex;
  align-items:center;
  margin-bottom:20px;

  label{
    font-size:12px;
    
    @media (max-width:480px){
      font-size:10px;
      position: relative;
      top: -3px;
    }
  }
`;

const StyledBreadCrumbs = styled.div`
  text-align: left;
  display: block;
  width: 100%;
  margin-bottom: 20px;
  color: #72879f;
  span {
    color: #ffd100;
  }

  a {
    color: #72879f;
  }
  a:hover {
    color: #ffd100;
  }
`;

export {
  FormWrapper,
  StyledWrapper,
  FormikSectionContainer,
  WelcomeContainer,
  FormikSection,
  FormGroup,
  TermsAndConditionWrapper,
  AddUserHeading,
  AddUserContainer,
  SelectFieldContainer,
  StyledBreadCrumbs,
};