import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from '../../pages/Login';
import { InterStateWrapper } from '../GlobalStyles';

const SignupLayout = (props) => (
  <InterStateWrapper>
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
    </Routes>
  </InterStateWrapper>
);

export default SignupLayout;
