import React from 'react';
import Modal from 'react-bootstrap/Modal';

const MyModal = ({ show, close, children, size }) => {
  const handleClose = () => close(false);
  size = typeof size === 'undefined' ? 'xl' : size;
  return (
    <Modal size={size} show={show} onHide={handleClose}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default MyModal;
