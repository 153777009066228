import { Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SignupLayout from "../containers/Layout/SignupLayout";
import NewAdminLayout from "./Layout/NewAdminLayout/NewAdminLayout";
import { GlobalStyles, AppContainer } from "./GlobalStyles";
import PageNotFound from "../pages/PageNotFound";

function App() {
  return (
    <AppContainer>
      <ToastContainer />
      <GlobalStyles />
      <Routes>
        <Route path="/admin/*" element={<NewAdminLayout />} />
        <Route path="/" element={<SignupLayout />} />
        <Route path="/auth/*" element={<SignupLayout />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </AppContainer>
  );
}

export default App;
