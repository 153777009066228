import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import dashboardReducer from './dashboardSlice';
import hotelReducer from './hotelSlice';
import userReducer from './usersSlice';

export default configureStore({
  reducer: {
    auth: authReducer,
    dashboard: dashboardReducer,
    hotel: hotelReducer,
    users: userReducer,
  },
});
