export const combineDateAndTime = (date, time) => {
    const timeString = time.getHours() + ':' + time.getMinutes() + ':00';
    let year = date.getFullYear();
    let month = date.getMonth() + 1; // Jan is 0, dec is 11
    let day = date.getDate();
    let dateString = '' + year + '-' + month + '-' + day;
    let arr = `${dateString} ${timeString}`.split(/[- :]/),
    combinedDateTime = new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]);
    return combinedDateTime;
};

export const daysOfTheWeek = (key) => {
  const _daysOfTheWeek = {
    0: "Sun", 1: "Mon", 2: "Tue", 3: "Wed", 4: "Thur", 5: "Fri", 6: "Sat"
  }
  return _daysOfTheWeek[key];
};

export const addHours = (numOfHours, date) => {
  const newDate = new Date(date);
  const thisDate = newDate.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
  return new Date(thisDate);
}

export const addSeconds = (seconds, date) => {
  const newDate = new Date(date);
  const thisDate = newDate.setSeconds(newDate.getSeconds() + seconds)
  return new Date(thisDate);
}

export const addTimeDuration = (valid_for_duration_frequency, duration, date) => {
  let durationInSeconds;
  switch (valid_for_duration_frequency) {
    case "minutes":
      durationInSeconds = addSeconds((duration * 60), date);
      break;
    case "day":
      durationInSeconds = addSeconds((duration * 86400), date);
      break;
    case "hours":
      durationInSeconds = addSeconds((duration * 3600), date);
        break;
    case "week":
      durationInSeconds = addSeconds((duration * 604800), date);
      break;
    case "month":
      durationInSeconds = addSeconds((duration * 2.628e+6), date);
      break;
    default:
      durationInSeconds = date;
  }
  return durationInSeconds;
}

export const getDateDifferenceInDays = (date1, date2) => {
  // Step 1: Create Date objects for the input dates
  const startDate = new Date(date1);
  const endDate = new Date(date2);

  // Step 2: Calculate the difference in milliseconds
  const differenceInMilliseconds = Math.abs(endDate.getTime() - startDate.getTime());

  // Step 3: Convert milliseconds to days
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

  return differenceInDays;
}
