import styled from "styled-components";

const MainContainer = styled.div``;

const StyledMainContainer = styled.div`
  height: 35vh;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;


  @media (max-width: 400px) {
    .logo-img {
      margin-top: 28%;
    }
  }
`;

const StyledFormWrapper = styled.div`
  margin-top: -3.5rem;   
  width: fit-content;
  margin: auto;
`;

const Logotext = styled.h2`
  color: #fff;
`;

const FormWrapper = styled.div`
  padding: 2rem;
  .label {
    color: #fff;
    text-shadow: 0 0 #fff;
    margin: 1rem 0 0 0;
  }
  .form-control {
    padding: 1rem;
    width: 30rem;
    @media (max-width: 600px) {
      width: 75vw;
    }
  }
  .eye-icon {
    float: right;
    position: relative;
    top: -2.5rem;
    right: 1rem;
    opacity: 0.5;
    font-size: 1.5rem;
  }
`;

const LoginButton = styled.div`
  button {
    border: none;
    background: #ffc73c;
    color: #fff;
    text-shadow: 0 0 #fff;
    padding: 0.7rem 6rem;
    border-radius: 16px;
  }
`;

export {
  MainContainer,
  StyledMainContainer,
  StyledFormWrapper,
  Logotext,
  FormWrapper,
  LoginButton,
};