import { Link } from 'react-router-dom';
import {
  StyledButton,
} from './style';

const PrimaryButton = ({ href, title, className, style }) => (
  <Link to={href}>
    <StyledButton className={(typeof className !== 'undefined') ? className : ''} style={typeof style !== 'undefined' ? style : {'color': '#fff', 'fontSize': '0.85rem', 'fontWeight': '600'}}>{title}</StyledButton>
  </Link>
);

export default PrimaryButton;
