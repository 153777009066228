import styled from "styled-components";

const StyledTable = styled.table.attrs({className: 'table'})`
  font-family: "Roboto", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #C2C2C2;

  .rt-table {
    overflow: auto;
  }
  .rt-td{
    padding: 10px 14px;
    font-size: 0.875rem;
    line-height: 1.5;
  }
  .first-column{
    font-size: 1rem !important;
    line-height: 1.6;
    color: #f8857d;
    text-transform: capitalize;
  }
  .rt-tbody .rt-tr-group{
    border: none; 
  }

  .ReactTable {
    width: 100%;

    .rt-tr {
      padding: 0.4rem;
    }
    .rt-tr {
      text-align: justify;
    }
    .rt-thead -filters {
      .rt-tr input {
        border-color: hsl(0, 0%, 80%);
        border-radius: 4px;
        border-style: solid;
        border-width: 1px;
      }
    }
    .rt-thead.-filters {
      border-bottom: none;
    }
    .-header {
      letter-spacing: -0.01em;
      color: #000;
      opacity: 1;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      border-bottom: 1px solid #efefef;
      box-shadow: none;
    }
    .react-grid-Cell {
      border: none;
    }
    .rt-td {
      border-right: none;
    }
    .rt-thead.-filters .rt-th {
      border-right: none;
    }
    .rt-td,
    .rt-resizable-header-content {
      white-space: normal;
      overflow-wrap: break-word;
      text-overflow: initial;
      text-align: left;
    }
    .name-column {
      color: #72879f;
    }
  }
  .ReactTable .rt-tbody {
    overflow-x: hidden;
  }
`;

export { StyledTable };
