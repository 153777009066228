import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { toggleLeftBar } from '../../../../redux/dashboardSlice';


const NavHeader = () => {
  const dispatch = useDispatch();
  const showLeftBarStatus = useSelector((state) => state.dashboard.showLeftBar);

  return (
    <div className="nav-header">
      <Link to="/admin" className="brand-logo">
        <img className="logo-abbr" src={require("../../../../assets/logo.png")} alt="logo" />
      </Link>
      <div className="nav-control" onClick={() => dispatch(toggleLeftBar())}>
        <div className={showLeftBarStatus ? "hamburger is-active" : "hamburger"}>
          <span className="line"></span><span className="line"></span><span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHeader;
