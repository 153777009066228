/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Inflow from '@plecco/inflow';
import { toast } from 'react-toastify';

export const authenticate = createAsyncThunk(
  'user/authenticate',
  async (values) => {
    try {
      const response = await Inflow.login(values);
      const data = await response.data;
      if (data.user) {
        const role = data.user.roles.length ? data.user.roles[0] : null;
        const token = response.headers.authorization;
        localStorage.setItem(
          'user',
          JSON.stringify({
            accessToken: token,
          })
        );
        localStorage.setItem("role", role);
      }
      return data;
    } catch (e) {
      return e?.response?.data;
    }
  }
);

export const userInfo = createAsyncThunk(
  'user/userInfo',
  async () => {
    const response = await Inflow.userInformation();
    if (response.status === "unauthorized") {
      authSlice?.logout();
    } else {
      return response;
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loading: false,
    error: null,
    data: {},
    isAuth: localStorage.getItem('user') ? true : false,
    role: null,
    userInfoLoading: false,
  },
  reducers: {
    logout: () => ({
      loading: false,
      error: null,
      data: null,
      isAuth: false,
    }),
  },
  extraReducers: {
    [authenticate.pending]: (state) => {
      state.loading = true;
    },
    [authenticate.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [authenticate.fulfilled]: (state, action) => {
      state.loading = false;
      if (typeof action.payload.user !== 'undefined') {
        state.isAuth = true;
        state.data = {
          userId: action?.payload?.user?.id,
          firstName: action?.payload?.user?.first_name,
          lastName: action?.payload?.user?.last_name,
          email: action?.payload?.user?.email,
          role: 'admin'
        };
      } else {
        toast.error(action.payload);
      }
    },
    [userInfo.pending]: (state) => {
      state.userInfoLoading = true;
    },
    [userInfo.rejected]: (state, action) => {
      state.userInfoLoading = false;
      toast.error(action.error.message);
      state.error = action.error.message;
    },
    [userInfo.fulfilled]: (state, action) => {
      state.userInfoLoading = false;
      state.data = action?.payload;
      state.role = action?.payload?.roles[0];
    },
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
