import styled from 'styled-components';

const StyledSectionTitle = styled.h1`
  font-size: 40px;
  letter-spacing: 0.05em;
  color: #362e2e;
  margin: 10px 0;
  text-transform: capitalize;

  @media (max-width: 767px) {
    font-size: 34px;
    line-height: 42px;
    margin-bottom: 10px;
  }
  @media (max-width: 480px) {
    font-size: 28px;
    line-height: 36px;
  }
`;

const StyledHeaderWrapper = styled.div`
  width: 98%;
`;

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 98%;
  
  .status .Expired {
    height: 15px;
    width: 15px;
    background-color: #38b000;
    border-radius: 50%;
    display: inline-block;
  }
  .Expired p {
    left: 20px;
    position: relative;
    font-size: 16px;
    font-weight: bolder;
    color: #38b000;
    top: -3px;
    letter-spacing: 0.1em;
  }
  .status .Active {
    height: 15px;
    width: 15px;
    background-color: #ff0000;
    border-radius: 50%;
    display: inline-block;
  }
  .Active p {
    left: 20px;
    position: relative;
    font-size: 16px;
    font-weight: bolder;
    color: #ff0000;
    top: -3px;
    letter-spacing: 0.09em;
  }
  .status {
    overflow-wrap: normal !important;
  }
`;

const StyledMainWrapper = styled.div`
  
`;

const StyledMainContainer = styled.div`
  display: ${props => props.view !== 'dashboard-view' ? 'grid' : 'block'};
  grid-template-columns: ${props => props.view !== 'dashboard-view' ? '0.7fr 5fr' : ''};
  min-height: ${props => props.view !== 'dashboard-view' ? '920px' : ''};
`;

const ParkingSessionAttributes = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 150px;
  width: 100%;

  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #72879f;
    opacity: 0.5;
  }
  a {
    color: #362e2e;
    display: block;
    word-break: break-word;
  }

  .operator-name-link {
    color: #72879f;
  }

  img.table-icon {
    width: 13px;
    margin-right: 12px;
  }
`;

export {
  StyledSectionTitle,
  StyledHeaderWrapper,
  // SearchInputAndAddOperator,
  StyledContentWrapper,
  StyledMainWrapper,
  StyledMainContainer,
  ParkingSessionAttributes,
};
