import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Inflow from '@plecco/inflow';

export const purchaseParking = createAsyncThunk(
  'hotel/purchaseParking',
  async ({
    parkingId,
    parking_session_start_datetime,
    parking_session_end_datetime,
    vehicle_license_number,
    parking_amount,
    location_id,
    currency,
    phone_number,
    first_name,
    last_name,
    email,
    room_no,
    unit_price,
    operator_id,
    rate
  }) => {
    try {
      const response = await Inflow.purchaseParkingSession({
        slug: parkingId,
        parking_session: {
          parking_session_start_datetime,
          parking_session_end_datetime,
          vehicle_license_number,
          parking_amount,
          location_id,
          currency,
          phone_number,
          first_name,
          last_name,
          email,
          room_no,
          unit_price,
          for_hotel: true,
          platform: "hotel",
          operator_id,
          rate_id: rate?.id,
          rate_name: rate?.rate_name,
          rate_amount: rate?.price
        }
      });
      const data = await response?.data;
      return data;
    } catch (e) {
      return e?.response?.data || { message: "Parking session failed to be created" }
    }
  }
);

export const getParkingSessions = createAsyncThunk(
  'hotel/getParkingSessions',
  async ({ selectedLocation, startDate, endDate }) => {
    const response = await Inflow.getParkingSession({ selectedLocation, startDate, endDate });
    const data = await response.data;
    return data;
  }
);

export const getParkingInfo = createAsyncThunk(
  'hotel/getParkingInfo',
  async (slug) => {
    const response = await Inflow.getParkingSessionInfo({ slug: slug });
    const data = await response.data;
    return data;
  }
);

export const conciergeOperatorListing = createAsyncThunk(
  'hotel/conciergeOperatorListing',
  async () => {
    const response = await Inflow.getOperatorsDetails();
    const data = await response.data;
    return data;
  }
);

export const hotelSlice = createSlice({
  name: 'hotel',
  initialState: {
    loading: false,
    parkingSessionLoading: false,
    error: null,
    data: [],
    userOperators: {},
    selectedGroup: 0,
    selectedLocation: 0,
    locationOptions: [],
    hotelRates: [],
    locationLabel: '',
    groupLabel: '',
  },
  reducers: {
    updateSelectedParams: (state, action) => {
      const { param, paramVal, locationOptions, locationLabel, hotelRates, groupLabel } = action.payload;
      if (param === 'selectedGroup') {
        state.selectedGroup = paramVal;
        state.locationOptions = locationOptions;
        state.selectedLocation = 0;
        state.locationLabel = locationLabel || '';
        state.groupLabel = groupLabel;
      } else if (param === 'selectedLocation') {
        state.selectedLocation = paramVal;
        state.hotelRates = hotelRates;
        state.locationLabel = locationLabel;
        state.groupLabel = groupLabel || '';
      }
    },
  },
  extraReducers: {
    [purchaseParking.pending]: (state) => {
      state.parkingSessionLoading = true;
    },
    [purchaseParking.rejected]: (state, action) => {
      state.parkingSessionLoading = false;
      state.error = action.error.message;
    },
    [purchaseParking.fulfilled]: (state, action) => {
      state.parkingSessionLoading = false;
      state.data = action.payload;
    },
    [getParkingSessions.pending]: (state) => {
      state.loading = true;
    },
    [getParkingSessions.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getParkingSessions.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [getParkingInfo.pending]: (state) => {
      state.loading = true;
    },
    [getParkingInfo.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getParkingInfo.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [conciergeOperatorListing.pending]: (state) => {
      state.loading = true;
    },
    [conciergeOperatorListing.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [conciergeOperatorListing.fulfilled]: (state, action) => {
      state.loading = false;
      state.userOperators = action.payload;
    }
  },
});

export const { updateSelectedParams } = hotelSlice.actions;

export default hotelSlice.reducer;
