import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactDataTable from "../../components/ReactDataTable";
import ReactDateRange from "../../components/ReactDateRange";
import { getParkingSessions } from "../../redux/hotelSlice";
import {
  StyledDashboardContainer,
  StyledMainWrapper,
  ReportSelect,
  ReportSelectDesc,
  SearchAndFilter,
  StyledHeaderWrapper,
  StyledDashboardWrapper,
  ParkingSessionAttributes,
  StyledSectionTitle,
} from "./style";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import moment from "moment";
import Loader from '../../components/Loader';

const columns = [
  {
    Header: "First Name",
    accessor: "first_name",
    Cell: (props) => (
      <p className="first-column">{props?.original?.first_name}</p>
    ),
  },
  {
    Header: "Last Name",
    accessor: "last_name",
  },
  {
    Header: "Room Number",
    accessor: "room_no",
  },
  {
    Header: "License Plate Number",
    accessor: "vehicle_license_number",
  },
  {
    Header: "Session Start Date",
    accessor: "parking_session_start_datetime",
    Cell: (props) =>
      props.original
        ? moment(new Date(props.original?.parking_session_start_datetime)).format('YYYY-MM-DD')
        : "",
  },
  {
    Header: "Session End Date",
    accessor: "parking_session_end_datetime",
    Cell: (props) =>
      props.original
        ? moment(new Date(props.original?.parking_session_end_datetime)).format('YYYY-MM-DD')
        : "",
  },
  {
    Header: "Convert Time",
    accessor: "convert_time",
    Cell: (props) =>
      props.original
        ? moment(new Date(props.original?.convert_details?.convert_time)).format('YYYY-MM-DD')
        : "",
  },
  {
    Header: "Convert Type",
    accessor: "convert_type",
    Cell: (props) =>
      props.original?.convert_details
        ? props.original?.convert_details?.convert_type
        : "-",
  },
  {
    Header: "Usage Day",
    accessor: "usage_days",
    Cell: (props) => props?.original?.usage_days === 0 ? 1 : props?.original?.usage_days
  },
  {
    Header: "Issued By",
    accessor: "user",
    Cell: (props) =>
      props?.original?.user
        ? `${props.original?.user?.first_name} ${props.original?.user?.last_name}`
        : "-",
  },
  {
    Header: "List Price",
    accessor: "id",
    Cell: (props) =>
      props?.original?.parking_amount
        ? `$ ${Number(props?.original?.parking_amount)?.toFixed(2)}`
        : "-",
  },
  {
    Header: "Action",
    accessor: "id",
    Cell: (props) => (
      <ParkingSessionAttributes>
        <Link to={`/admin/session-details/${props?.original?.slug}/edit`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="18"
            width="22"
            viewBox="0 0 512 512"
          >
            <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
          </svg>
        </Link>
      </ParkingSessionAttributes>
    ),
    filterable: false,
    sortable: false,
  },
  {
    Header: "Status",
    accessor: "expired",
    className: "status",
    Cell: (props) => (
      <span
        className={props?.original?.expired !== false ? "Active" : "Expired"}
      >
        <p>{props?.original?.expired !== false ? "Expired" : "Active"}</p>
      </span>
    ),
    filterable: false,
    sortable: false,
  },
];

const Reports = () => {
  const date = new Date();
  const [dateFilterRange, setDateFilterRange] = useState({
    startDate: new Date(date.getFullYear(), date.getMonth(), 1),
    endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
  });
  const dispatch = useDispatch();
  const startDate = dateFilterRange?.[0]?.startDate?.toISOString();
  const endDate = dateFilterRange?.[0]?.endDate?.toISOString();
  const hotelData = useSelector((state) => state.hotel);
  const { selectedGroup, selectedLocation, locationLabel, groupLabel, loading } =
    hotelData;
  const ref = useRef(null);
  const reports = useSelector((state) => state?.hotel?.data?.parking_sessions?.filter(parkingSession => parkingSession?.operator_id === selectedGroup));
  const [reportsArray, setReportsArray] = useState([]);

  useEffect(() => {
    if (typeof selectedLocation !== "undefined" && selectedLocation !== 0) {
      dispatch(getParkingSessions({ selectedLocation, startDate, endDate }));
    }
  }, [dispatch, selectedLocation, startDate, endDate]);

  useEffect(() => {
    let start = new Date(dateFilterRange?.[0]?.startDate);
    let end = new Date(dateFilterRange?.[0]?.endDate);
    let reportArr = [];
    let current = new Date(start);
    let grandTotal = 0;
    while (current <= end && typeof reports !== "undefined") {
      let total = 0;
      let nightlyReportsData = reports
        ?.filter((report) => {
          if (
            new Date(report.parking_session_start_datetime) <= current &&
            new Date(report.parking_session_end_datetime) >= current
          ) {
            return report;
          }
        })
        ?.map((report) => {
          total += report?.parking_session_hotel?.rate_amount ?
          parseFloat(report?.parking_session_hotel?.rate_amount) : report?.usage_days && parseFloat(report?.usage_days) > 0
          ? parseFloat(report?.parking_amount) / parseInt(report?.usage_days)
          : parseFloat(report?.parking_amount)
          const reportDate = moment(new Date(current)).subtract(1, 'days').format('YYYY-MM-DD')
          return {
            "Start Date": moment(new Date(report.parking_session_start_datetime)).format('YYYY-MM-DD'),
            "End Date": moment(new Date(report.parking_session_end_datetime)).format('YYYY-MM-DD'),
            "Last Name": report.last_name,
            "First Name": report.first_name,
            "Room Number": report.room_no,
            "Licence Plate Number": report.vehicle_license_number,
            "Report Date": reportDate,
            "Group Id": selectedGroup,
            "Group Name": groupLabel,
            "Location Id": selectedLocation,
            "Location Name": locationLabel,
            "Guest Rate": report?.parking_session_hotel?.rate_amount ?
            parseFloat(report?.parking_session_hotel?.rate_amount) :
            report?.usage_days && parseFloat(report?.usage_days) > 0
                ? parseFloat(report?.parking_amount) / parseInt(report?.usage_days)
                : parseFloat(report?.parking_amount),
          };
        });
      if (nightlyReportsData.length) {
        reportArr.push(nightlyReportsData);
        reportArr.push([
          {
            "Start Date": "Total",
            "End Date": "",
            "Last Name": "",
            "First Name": "",
            "Room Number": "",
            "Licence Plate Number": "",
            "Report Date": "",
            "Group Id": "",
            "Group Name": "",
            "Location Id": "",
            "Location Name": "",
            "Guest Rate":  parseFloat(total),
          },
        ]);
        reportArr.push([{}]);
      }
      grandTotal +=
        typeof total !== "undefined" && total !== null && total !== ""
          ? total
          : 0;
      current = new Date(
        current.getFullYear(),
        current.getMonth(),
        current.getDate() + 1
      );
    }
    if (reportArr.length) {
      reportArr.push([
        {
          "Start Date": "Grand Total",
          "End Date": "",
          "Last Name": "",
          "First Name": "",
          "Room Number": "",
          "Licence Plate Number": "",
          "Report Date": "",
          "Group Id": "",
          "Group Name": "",
          "Location Id": "",
          "Location Name": "",
          "Guest Rate": parseFloat(grandTotal),
        },
      ]);
      setReportsArray(reportArr);
    }
    return () => {
      setReportsArray([]);
    };
  }, [dateFilterRange, reports, groupLabel, locationLabel, selectedGroup, selectedLocation]);

  let reportsData = reports?.map((report) => {
    return {
      "First Name": report?.first_name,
      "Last Name": report?.last_name,
      "Room Number": report?.room_no,
      Email: report?.email,
      "Session Start Time":
      moment(new Date(report.parking_session_start_datetime)).format('YYYY-MM-DD'),
      "Session End Date": moment(new Date(report.parking_session_end_datetime)).format('YYYY-MM-DD'),
      "Convert Time": moment(new Date(report?.convert_details?.convert_time)).format('YYYY-MM-DD'),
      "Convert type": report?.convert_details?.convert_type,
      "Usage Day": report?.usage_days === 0 ? 1 : parseInt(report?.usage_days),
      "Issued By": report?.user  ? `${report?.user?.first_name} ${report?.user?.last_name}` : '-',
    };
  });
 
  let totalUsageDays = 0;
  let totalCharges = 0;
  let totalBillBackCharge = 0;
  let billBackRep = reports?.map((report) => {
    totalUsageDays += report?.usage_days === 0 ? 1 :  report?.usage_days;
    totalCharges +=
      report?.parking_amount
        ? parseFloat(report?.parking_amount)
        : 0;
    totalBillBackCharge +=
      typeof report.unit_price !== "undefined" &&
      typeof report.usage_days !== "undefined" &&
      report.unit_price !== null
        ? parseInt(report.usage_days) * parseFloat(report.unit_price)
        : 0;
    return {
      "Reservation Last Name": report?.last_name,
      "Reservation First Name": report?.first_name,
      "Room Number": report?.room_no,
      "Licence Plate Number": report?.vehicle_license_number,
      "Start Date": moment(new Date(report.parking_session_start_datetime)).format('YYYY-MM-DD'),
      "End Date": moment(new Date(report.parking_session_end_datetime)).format('YYYY-MM-DD'),
      "Usage Days": report?.usage_days === 0 ? 1 : report?.usage_days,
      "Total Charges for Stay (to Date)":
        report.parking_amount !== null
          ? parseFloat(report.parking_amount)
          : 0,
      "Unit Price":
        typeof report.unit_price !== "undefined" && report.unit_price !== null
          ? parseFloat(report.unit_price)
          : 0,
      "Bill Back Rate":
        typeof report.unit_price !== "undefined" &&
        typeof report.usage_days !== "undefined" &&
        report.unit_price !== null
          ? parseInt(report.usage_days) * parseFloat(report.unit_price)
          : 0,
      "Group Id": selectedGroup,
      "Group Name": groupLabel,
      "Location Id": selectedLocation,
      "Location Name": locationLabel,
    };
  });
  if (billBackRep?.length) {
    billBackRep.push([{}]);
    billBackRep.push([
      {
        "Reservation Last Name": "Total",
        "Reservation First Name": "",
        "Room Number": "",
        "Licence Plate Number": "",
        "Start Date": "",
        "End Date": "",
        "Usage Days": totalUsageDays,
        "Total Charges for Stay (to Date)": totalCharges,
        "Unit Price": "",
        "Bill Back Rate": totalBillBackCharge,
        "Group Id": "",
        "Group Name": "",
        "Location Id": "",
        "Location Name": "",
      },
    ]);
  }

  return (
    <StyledDashboardContainer>
      <StyledHeaderWrapper>
        {locationLabel && (
          <StyledSectionTitle>{`Report : ${locationLabel}`}</StyledSectionTitle>
        )}
      </StyledHeaderWrapper>
      <StyledMainWrapper>
        {loading ? <Loader /> : null}
        <StyledHeaderWrapper>
          <SearchAndFilter>
            <CSVLink
              data={
                typeof billBackRep !== "undefined" ? billBackRep?.flat() : []
              }
              className="csv-link btn btn-primary text-uppercase"
              filename={`${locationLabel}s Bill Back Report`}
            >
              Download Bill Back Report
            </CSVLink>
            <CSVLink
              data={
                typeof reportsArray !== "undefined" ? reportsArray?.flat() : []
              }
              className="csv-link btn btn-primary text-uppercase"
              filename={`${locationLabel}s Nightly Report`}
            >
              Download Nightly Report
            </CSVLink>
            <CSVLink
              data={typeof reportsData !== "undefined" ? reportsData : []}
              className="csv-link btn btn-primary"
              filename={`${locationLabel}s Transaction Summary`}
            >
              Download CSVs
            </CSVLink>
            <ReportSelect className="mb-2 ms-2">
              <ReportSelectDesc>Your Past Report: </ReportSelectDesc>
              <ReactDateRange setDateFilterRange={setDateFilterRange} />
            </ReportSelect>
          </SearchAndFilter>
        </StyledHeaderWrapper>
        <StyledDashboardWrapper ref={ref}>
          <ReactDataTable
            filterable={true}
            data={typeof reports !== "undefined" ? reports : []}
            columns={columns}
          />
        </StyledDashboardWrapper>
      </StyledMainWrapper>
    </StyledDashboardContainer>
  );
};
export default Reports;
